export const convertToKWH = (wh: number | undefined) => {
  if (!wh) return 0
  return (wh / 1000).toFixed(2)
}

export const calculateCO2Saved = (whConsumed: number): string => {
  // Average CO2 emissions from the electricity mix in Spain 2023: ~150g CO2/kWh
  const CO2_PER_KWH_ELECTRIC = 0.15 // kg CO2/kWh
  // Average CO2 emissions for fossil fuels (petrol equivalent): ~250g CO2/kWh
  const CO2_PER_KWH_GASOLINE_EQUIV = 0.25 // kg CO2/kWh
  // Calculate CO2 savings by using electricity instead of fossil fuels
  const co2Saved = (whConsumed / 1000) * (CO2_PER_KWH_GASOLINE_EQUIV - CO2_PER_KWH_ELECTRIC)
  return co2Saved.toFixed(2)
}

export const calculateFuelSaved = (whConsumed: number): string => {
  /*Average fuel consumption of a combustion car: ~7L/100km
    One litre of petrol is approximately equivalent to 9.7 kWh*/
  const KWH_PER_LITER = 9.7
  return (whConsumed / 1000 / KWH_PER_LITER).toFixed(2)
}
