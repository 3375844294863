import type { MeterValuesRequest } from '@/models/ocpp/MeterValuesRequest'
import type { StatusNotificationRequest } from '@/models/ocpp/StatusNotification'
import type {
  ChangeAvailabilityRequest,
  ReserveNowRequest,
  UnlockConnectorRequest
} from '@/models/ocpp/Requests'
import type {
  ChangeAvailabilityResponse,
  ReserveNowResponse,
  UnlockConnectorResponse
} from '@/models/ocpp/Responses'
import type { TopicNameSpace } from '@/models/common/TopicNameSpace'
import { WebSocketStatus } from '@/models/ocpp/enums'

interface BroadcastAbstract {
  cpId: string
  messageType: number
  uniqueId: string
  action: TopicNameSpace
}

export interface MeterValuesBroadcast extends BroadcastAbstract {
  payload: MeterValuesRequest
}

export interface StatusNotificationBroadcast extends BroadcastAbstract {
  payload: StatusNotificationRequest
}

export interface ChangeAvailabilityBroadcast extends BroadcastAbstract {
  payload: ChangeAvailabilityResponse
  request: ChangeAvailabilityRequest
}

export interface UnlockConnectorBroadcast extends BroadcastAbstract {
  payload: UnlockConnectorResponse
  request: UnlockConnectorRequest
}

export interface ReserveNowBroadcast extends BroadcastAbstract {
  payload: ReserveNowResponse
  request: ReserveNowRequest
}

export interface ChargerStatus {
  cpId: string
  action: string
  status: WebSocketStatus
}

export enum StatusRequest {
  PENDING = 'Pending',
  DONE = 'Done'
}

export interface ActionRequested {
  cpId: string
  connectorId: number
  action: TopicNameSpace
  command: TopicNameSpace
  statusRequest: StatusRequest
  target: string
}
