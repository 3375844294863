<script setup lang="ts">
import { onMounted, ref, toRefs, watch } from 'vue'
import L, { type Map } from 'leaflet'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster'
import type { PositionEditable } from '@/models/ui/Position'
import { t } from '@/common/i18n'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { capitalizeString } from '@/utils/capitalize'
import type { Cluster } from '@/models/ui/Map'
import { attribution, COLORS, STATES } from '@/models/ui/Map'
import type { StationsParams } from '@/models/ui/Stations'

const props = defineProps<PositionEditable>()
const emit = defineEmits(['update'])
const maxBounds = 0.0001
const {
  coords,
  available,
  unavailable,
  suspended,
  stations,
  zoom,
  isDraggable,
  coordsLocation,
  coordsCharger
} = toRefs(props)
const mapElement = ref<HTMLElement | null>(null)
let leafletMap: Map | undefined
const leafletControl = new L.Control({
  position: 'bottomright'
})
const tiles = L.tileLayer(import.meta.env.VITE_OPENSTREETMAP, {
  maxZoom: 19,
  attribution
})

const latLng = L.latLng(41, 0)
const getSvgIcon = (color: string) => {
  return `<?xml version="1.0"?>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M12,2.01C8.14,2.01,5.01,5.15,5.01,9c0,3.957,4.395,10.373,6.215,12.631c0.4,0.496,1.151,0.496,1.55,0 C14.595,19.373,18.99,12.957,18.99,9C18.99,5.15,15.86,2.01,12,2.01z M11,17v-5H9l4-7v5h2L11,17z"
            fill="${color}" stroke="${color}" stroke-width="0"/>
  </svg>`
}

const clusterIconCreate = (cluster: Cluster) => {
  return new L.DivIcon({
    html: `<div><span>${cluster.getChildCount()}</span></div>`,
    className: 'marker-cluster',
    iconSize: new L.Point(40, 40)
  })
}
const markers = L.markerClusterGroup({
  iconCreateFunction: clusterIconCreate,
  chunkedLoading: true,
  spiderfyOnMaxZoom: false,
  disableClusteringAtZoom: 18,
  showCoverageOnHover: false,
  maxClusterRadius: 50,
  spiderLegPolylineOptions: {
    opacity: 0
  }
})

const iconColor = (state: string) => {
  const color =
    state === STATES.available
      ? COLORS.available
      : state === STATES.unavailable
        ? COLORS.unavailable
        : COLORS.suspended

  return L.divIcon({
    className: 'marker',
    html: getSvgIcon(color),
    iconSize: [28, 28],
    iconAnchor: [12, 24],
    popupAnchor: [0, -26]
  })
}

const getLocationCounts = (matchingLocation: any) => {
  const availableChargers = matchingLocation
    ? matchingLocation[1].filter((station: StationsParams) => {
        return station['statusCharger'] === 0
      }).length
    : 0
  const unavailableChargers = matchingLocation
    ? matchingLocation[1].filter((station: StationsParams) => {
        return station['statusCharger'] === 1
      }).length
    : 0
  const incidenceChargers = matchingLocation
    ? matchingLocation[1].filter((station: StationsParams) => {
        return station['statusCharger'] === 2
      }).length
    : 0
  return { availableChargers, unavailableChargers, incidenceChargers }
}

const getLocationStatus = (matchingLocation: any) => {
  const { unavailableChargers, incidenceChargers } = getLocationCounts(matchingLocation)
  if (unavailableChargers !== 0) {
    return STATES.unavailable
  }
  if (incidenceChargers !== 0) {
    return STATES.suspended
  } else {
    return STATES.available
  }
}
const getStatus = (id: string) => {
  const matchingLocation = stations.value
    ? stations.value.find((location: any) => location[0] === id)
    : undefined
  return getLocationStatus(matchingLocation)
}

const htmlPopup = (popupContent: string, id: string) => {
  const matchingLocation = stations.value
    ? stations.value.find((location: any) => location[0] === id)
    : undefined
  const numbersCPLocation = matchingLocation ? matchingLocation[1].length : 0
  const state = getLocationStatus(matchingLocation)
  const { availableChargers, unavailableChargers, incidenceChargers } =
    getLocationCounts(matchingLocation)

  return `
    <a href="${RouteNamespace.locations}/${id}" class="text-gray-600">
        <div class="flex flex-column align-items-center">
            <div class="flex flex-row">
                <div class="status status__${state}"></div>
                <span class="font-bold ml-2">${capitalizeString(popupContent)}</span>
            </div>
        </div>
            <div class="p-2">
            <div class="flex flex-row">
                <span>${numbersCPLocation} ${t('chargePoints')}</span>
            </div>
            <div class="flex flex-row">
                <span>${availableChargers} ${t('status.available')}</span>
            </div>
            <div class="flex flex-row">
                <span>${unavailableChargers} ${t('status.unavailable')}</span>
            </div>
            <div class="flex flex-row">
                <span>${incidenceChargers} ${t('status.incidence')}</span>
            </div>
</div>
    </a>
`
}
const createLegendRow = (color: string, label: string, count: number) => `
  <div class="w-11rem pl-3 pr-3">
    <a href="" class="text-gray-700">
      <div class="flex flex-row justify-content-between p-1 mb-1">
        <div class="flex flex-row mt-2 mb-1">
          <i class="status status__${color}"></i>
          <span class="font-bold ml-2">${label}</span>
        </div>
        <div class="mt-2">${count}</div>
      </div>
    </a>
  </div>`

const clickPan = (e: L.LeafletEvent) => {
  if (leafletMap && e.target) leafletMap.panTo((e.target as L.Marker).getLatLng())
}
const setMapBounds = (
  map: L.Map,
  coordsLocation: { value: [number, number, number] },
  maxBounds: number
) => {
  const southWest = L.latLng(
    coordsLocation.value[0] + maxBounds,
    coordsLocation.value[1] + maxBounds
  )
  const northEast = L.latLng(
    coordsLocation.value[0] - maxBounds,
    coordsLocation.value[1] - maxBounds
  )
  const myBounds = L.latLngBounds(southWest, northEast)
  map.setMaxBounds(myBounds)
  map.setMinZoom(map.getZoom())
}
const initMap = (element: HTMLElement) => {
  leafletMap?.remove()
  const map = L.map(element, { center: latLng, zoom: zoom.value, layers: [tiles] })
  leafletMap = map
  if (coordsCharger.value) {
    setMapBounds(map, { value: coordsCharger.value }, maxBounds)
  }
  const markersGroup = L.layerGroup()
  map.addLayer(markersGroup)
  if (!coordsCharger.value) {
    map.on('click', (e) => {
      const markersCount = 1
      if (markersCount) {
        const customIcon = L.divIcon({
          className: 'marker',
          html: getSvgIcon('#00DB7F'),
          iconSize: [28, 28],
          iconAnchor: [12, 24],
          popupAnchor: [0, -26]
        })
        if (coordsLocation.value) {
          markersGroup.clearLayers()
          emit('update', e.latlng) //emit the new coordinates
          return L.marker(e.latlng, { icon: customIcon }).addTo(markersGroup)
        }
      }
    })
  } else {
    map.on('click', (e) => {
      const state =
        coordsCharger.value![2] === 0
          ? STATES.available
          : coordsCharger.value![2] === 1
            ? STATES.unavailable
            : STATES.suspended
      const icon = iconColor(state)
      markers.clearLayers()
      markersGroup.clearLayers()
      emit('update', e.latlng)
      return L.marker(e.latlng, { icon: icon }).addTo(markersGroup)
    })
  }

  const div = L.DomUtil.create('div', 'legend')
  const color = [STATES.available, STATES.unavailable, STATES.suspended]
  const label = [
    `${t('status.available')}`,
    `${t('status.unavailable')}`,
    `${t('status.incidence')}`
  ]
  leafletControl.onAdd = () => {
    const availableCount = props.available || 0
    const unavailableCount = props.unavailable || 0
    const incidenceCount = props.suspended || 0

    const legendRows = color
      .map((condition, i) => {
        let statusCount = 0
        if (condition === 'available') {
          statusCount = availableCount
        } else if (condition === 'unavailable') {
          statusCount = unavailableCount
        } else if (condition === 'suspended') {
          statusCount = incidenceCount
        }
        return createLegendRow(condition, label[i], statusCount)
      })
      .join('')
    div.innerHTML = `<div class=" bg-white border-round-xl">${legendRows}</div>`
    if (coordsLocation.value) {
      const newLat = coordsLocation.value[0]
      const newLng = coordsLocation.value[1]
      map.setView([newLat, newLng])
      div.innerHTML = `<div class=" bg-white border-round-xl"></div>`
    }
    if (coordsCharger.value) {
      const newLat = coordsCharger.value[0]
      const newLng = coordsCharger.value[1]
      map.setView([newLat, newLng])
      div.innerHTML = `<div class=" bg-white border-round-xl"></div>`
    }
    return div
  }
  leafletControl.addTo(map)
  markers.clearLayers()
  if (Array.isArray(coords.value)) {
    coords.value.forEach((item: any) => {
      const [id, popupContent, active, latitude, longitude] = item
      const state = getStatus(id)
      const icon = iconColor(state)
      const marker = L.marker([latitude, longitude], { icon, draggable: isDraggable.value })
        .bindPopup(htmlPopup(popupContent, id))
        .on('click', clickPan)
      markers.addLayer(marker)
    })
  }

  if (coordsCharger.value) {
    const state =
      coordsCharger.value[2] === 0
        ? STATES.available
        : coordsCharger.value[2] === 1
          ? STATES.unavailable
          : STATES.suspended
    const icon = iconColor(state)
    const marker = L.marker([coordsCharger.value[0], coordsCharger.value[1]], {
      icon,
      draggable: isDraggable.value
    }).on('click', clickPan)
    markers.addLayer(marker)
  }
  map.addLayer(markers)
  return map
}
watch(
  [coords, available, unavailable, suspended, stations],
  () => {
    if (mapElement.value) {
      leafletMap = initMap(mapElement.value!)
    }
  },
  { deep: true }
)
const verifyMapElement = () => {
  if (mapElement.value) {
    leafletMap?.invalidateSize()
    if (Array.isArray(coords.value) && coords.value.length === 0) {
      leafletMap = initMap(mapElement.value!)
    }
  } else {
    console.error('error')
  }
}

onMounted(verifyMapElement)
</script>

<template>
  <div id="map" ref="mapElement" :style="{ height: props.height }" />
</template>

<style scoped lang="scss">
.legend-area {
  border-radius: 10px;
  padding: 5em !important;
  background: var(--lightGray) !important;
}

.leaflet-container {
  z-index: 0;
}
</style>
