export enum AuthorizationStatus {
  ACCEPTED = 'Accepted',
  BLOCKED = 'Blocked',
  EXPIRED = 'Expired',
  INVALID = 'Invalid',
  CONCURRENT_TX = 'ConcurrentTx'
}

export enum AvailabilityStatus {
  AVAILABILITY_ACCEPTED = 'Accepted',
  AVAILABILITY_REJECTED = 'Rejected',
  AVAILABILITY_SCHEDULED = 'Scheduled'
}

export enum AvailabilityType {
  INOPERATIVE = 'Inoperative',
  OPERATIVE = 'Operative'
}

export enum CancelReservationStatus {
  CANCEL_RESERVATION_ACCEPTED = 'Accepted',
  CANCEL_RESERVATION_REJECTED = 'Rejected'
}

export enum ChargePointErrorCode {
  CONNECTOR_LOCK_FAILURE = 'ConnectorLockFailure',
  EV_COMMUNICATION_ERROR = 'EVCommunicationError',
  GROUND_FAILURE = 'GroundFailure',
  HIGH_TEMPERATURE = 'HighTemperature',
  INTERNAL_ERROR = 'InternalError',
  LOCAL_LIST_CONFLICT = 'LocalListConflict',
  NO_ERROR = 'NoError',
  OTHER_ERROR = 'OtherError',
  OVER_CURRENT_FAILURE = 'OverCurrentFailure',
  OVER_VOLTAGE = 'OverVoltage',
  POWER_METER_FAILURE = 'PowerMeterFailure',
  POWER_SWITCH_FAILURE = 'PowerSwitchFailure',
  READER_FAILURE = 'ReaderFailure',
  RESET_FAILURE = 'ResetFailure',
  UNDER_VOLTAGE = 'UnderVoltage',
  WEAK_SIGNAL = 'WeakSignal'
}

export enum ChargePointStatus {
  AVAILABLE = 'Available',
  PREPARING = 'Preparing',
  CHARGING = 'Charging',
  SUSPENDED_EVSE = 'SuspendedEVSE',
  SUSPENDED_EV = 'SuspendedEV',
  FINISHING = 'Finishing',
  RESERVED = 'Reserved',
  UNAVAILABLE = 'Unavailable',
  FAULTED = 'Faulted'
}

export enum StationStatus {
  AVAILABLE = 'Available',
  UNAVAILABLE = 'Unavailable',
  FAULTED = 'Faulted'
}

export enum ChargingProfilePurposeType {
  CHARGE_POINT_MAX_PROFILE = 'ChargePointMaxProfile',
  TX_DEFAULT_PROFILE = 'TxDefaultProfile',
  TX_PROFILE = 'TxProfile'
}

export enum ChargingProfileKindType {
  ABSOLUTE = 'Absolute',
  RECURRING = 'Recurring',
  RELATIVE = 'Relative'
}

export enum ChargingProfileStatus {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  NOT_SUPPORTED = 'NotSupported'
}

export enum ChargingRateUnitType {
  W = 'W',
  A = 'A'
}

export enum ClearCacheStatus {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected'
}

export enum ClearChargingProfileStatus {
  ACCEPTED = 'Accepted',
  UNKNOWN = 'Unknown'
}

export enum ConfigurationStatus {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  REBOOT_REQUIRED = 'RebootRequired',
  NOT_SUPPORTED = 'NotSupported'
}

export enum DataTransferStatus {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  UNKNOWN_MESSAGE_ID = 'UnknownMessageId',
  UNKNOWN_VENDOR_ID = 'UnknownVendorId'
}

export enum DiagnosticsStatus {
  IDLE = 'Idle',
  UPLOADED = 'Uploaded',
  UPLOAD_FAILED = 'UploadFailed',
  UPLOADING = 'Uploading'
}

export enum FirmwareStatus {
  DOWNLOADED = 'Downloaded',
  DOWNLOAD_FAILED = 'DownloadFailed',
  DOWNLOADING = 'Downloading',
  IDLE = 'Idle',
  INSTALLATION_FAILED = 'InstallationFailed',
  INSTALLING = 'Installing',
  INSTALLED = 'Installed'
}

export enum GetCompositeScheduleStatus {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected'
}

export enum Location {
  BODY = 'Body',
  CABLE = 'Cable',
  EV = 'EV',
  INLET = 'Inlet',
  OUTLET = 'Outlet'
}

export enum Measurand {
  CURRENT_EXPORT = 'Current.Export',
  CURRENT_IMPORT = 'Current.Import',
  CURRENT_OFFERED = 'Current.Offered',
  ENERGY_ACTIVE_EXPORT_REGISTER = 'Energy.Active.Export.Register',
  ENERGY_ACTIVE_IMPORT_REGISTER = 'Energy.Active.Import.Register',
  ENERGY_REACTIVE_EXPORT_REGISTER = 'Energy.Reactive.Export.Register',
  ENERGY_REACTIVE_IMPORT_REGISTER = 'Energy.Reactive.Import.Register',
  ENERGY_ACTIVE_EXPORT_INTERVAL = 'Energy.Active.Export.Interval',
  ENERGY_ACTIVE_IMPORT_INTERVAL = 'Energy.Active.Import.Interval',
  ENERGY_REACTIVE_EXPORT_INTERVAL = 'Energy.Reactive.Export.Interval',
  ENERGY_REACTIVE_IMPORT_INTERVAL = 'Energy.Reactive.Import.Interval',
  FREQUENCY = 'Frequency',
  POWER_ACTIVE_EXPORT = 'Power.Active.Export',
  POWER_ACTIVE_IMPORT = 'Power.Active.Import',
  POWER_FACTOR = 'Power.Factor',
  POWER_OFFERED = 'Power.Offered',
  POWER_REACTIVE_EXPORT = 'Power.Reactive.Export',
  POWER_REACTIVE_IMPORT = 'Power.Reactive.Import',
  RPM = 'RPM',
  SOC = 'SoC',
  TEMPERATURE = 'Temperature',
  VOLTAGE = 'Voltage'
}

export enum MessageTrigger {
  BOOT_NOTIFICATION = 'BootNotification',
  DIAGNOSTICS_STATUS_NOTIFICATION = 'DiagnosticsStatusNotification',
  FIRMWARE_STATUS_NOTIFICATION = 'FirmwareStatusNotification',
  HEARTBEAT = 'Heartbeat',
  METER_VALUES = 'MeterValues',
  STATUS_NOTIFICATION = 'StatusNotification'
}

export enum Phase {
  L1 = 'L1',
  L2 = 'L2',
  L3 = 'L3',
  N = 'N',
  L1_N = 'L1-N',
  L2_N = 'L2-N',
  L3_N = 'L3-N',
  L1_L2 = 'L1-L2',
  L2_L3 = 'L2-L3',
  L3_L1 = 'L3-L1'
}

export enum ReadingContext {
  INTERRUPTION_BEGIN = 'Interruption.Begin',
  INTERRUPTION_END = 'Interruption.End',
  OTHER = 'Other',
  SAMPLE_CLOCK = 'Sample.Clock',
  SAMPLE_PERIODIC = 'Sample.Periodic',
  TRANSACTION_BEGIN = 'Transaction.Begin',
  TRANSACTION_END = 'Transaction.End',
  TRIGGER = 'Trigger'
}

export enum Reason {
  DE_AUTHORIZED = 'DeAuthorized',
  EMERGENCY_STOP = 'EmergencyStop',
  EV_DISCONNECTED = 'EVDisconnected',
  HARD_RESET = 'HardReset',
  LOCAL = 'Local',
  OTHER = 'Other',
  POWER_LOSS = 'PowerLoss',
  REBOOT = 'Reboot',
  REMOTE = 'Remote',
  SOFT_RESET = 'SoftReset',
  UNLOCK_COMMAND = 'UnlockCommand'
}

export enum RecurrencyKindType {
  DAILY = 'Daily',
  WEEKLY = 'Weekly'
}
export enum RegistrationStatus {
  ACCEPTED = 'Accepted',
  PENDING = 'Pending',
  REJECTED = 'Rejected'
}

export enum RemoteStartStopStatus {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected'
}

export enum ReservationStatus {
  ACCEPTED = 'Accepted',
  FAULTED = 'Faulted',
  OCCUPIED = 'Occupied',
  REJECTED = 'Rejected',
  UNAVAILABLE = 'Unavailable'
}

export enum ResetStatus {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected'
}

export enum ResetType {
  HARD = 'Hard',
  SOFT = 'Soft'
}

export enum TriggerMessageStatus {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  NOT_IMPLEMENTED = 'NotImplemented'
}

export enum UnitOfMeasure {
  WH = 'Wh',
  KWH = 'kWh',
  MWH = 'mWh',
  VARH = 'varh',
  KVARH = 'kvarh',
  W = 'W',
  KW = 'kW',
  VA = 'VA',
  KVA = 'kVA',
  VAR = 'var',
  KVAR = 'kvar',
  A = 'A',
  V = 'V',
  CELSIUS = 'Celsius',
  FAHRENHEIT = 'Fahrenheit',
  KELVIN = 'K',
  PERCENT = 'Percent'
}

export enum UnlockStatus {
  UNLOCKED = 'Unlocked',
  UNLOCK_FAILED = 'UnlockFailed',
  NOT_SUPPORTED = 'NotSupported'
}

export enum UpdateStatus {
  ACCEPTED = 'Accepted',
  FAILED = 'Failed',
  NOT_SUPPORTED = 'NotSupported',
  VERSION_MISMATCH = 'VersionMismatch'
}

export enum UpdateType {
  DIFFERENTIAL = 'Differential',
  FULL = 'Full'
}

export enum ValueFormat {
  RAW = 'Raw',
  SIGNED_DATA = 'SignedData'
}

export enum WebSocketStatus {
  CONNECTED = 'Connected',
  DISCONNECTED = 'Disconnected'
}

export enum Action {
  WEBSOCKET_STATUS = 'WebSocketStatus',
  AUTHORIZE = 'Authorize',
  BOOT_NOTIFICATION = 'BootNotification',
  CHANGE_AVAILABILITY = 'ChangeAvailability',
  CHANGE_CONFIGURATION = 'ChangeConfiguration',
  CLEAR_CACHE = 'ClearCache',
  DATA_TRANSFER = 'DataTransfer',
  GET_CONFIGURATION = 'GetConfiguration',
  HEARTBEAT = 'Heartbeat',
  METER_VALUES = 'MeterValues',
  REMOTE_START_TRANSACTION = 'RemoteStartTransaction',
  REMOTE_STOP_TRANSACTION = 'RemoteStopTransaction',
  RESET = 'Reset',
  START_TRANSACTION = 'StartTransaction',
  STATUS_NOTIFICATION = 'StatusNotification',
  STOP_TRANSACTION = 'StopTransaction',
  UNLOCK_CONNECTOR = 'UnlockConnector',
  GET_DIAGNOSTICS = 'GetDiagnostics',
  DIAGNOSTICS_STATUS_NOTIFICATION = 'DiagnosticsStatusNotification',
  FIRMWARE_STATUS_NOTIFICATION = 'FirmwareStatusNotification',
  UPDATE_FIRMWARE = 'UpdateFirmware',
  GET_LOCAL_LIST_VERSION = 'GetLocalListVersion',
  SEND_LOCAL_LIST = 'SendLocalList',
  CANCEL_RESERVATION = 'CancelReservation',
  RESERVE_NOW = 'ReserveNow',
  CLEAR_CHARGING_PROFILE = 'ClearChargingProfile',
  GET_COMPOSITE_SCHEDULE = 'GetCompositeSchedule',
  SET_CHARGING_PROFILE = 'SetChargingProfile',
  TRIGGER_MESSAGE = 'TriggerMessage'
}
