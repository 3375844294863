<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import router from '@/router'
import { useToast } from 'primevue/usetoast'
import { FilterMatchMode } from '@primevue/core/api'
import type { PageState } from 'primevue/paginator'
import type { DataTableRowClickEvent } from 'primevue/datatable'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { useApi } from '@/stores/api/api'
import { t } from '@/common/i18n'
import { ApiService } from '@/services/ApiService'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import {
  type CoordinateLocation,
  type Location,
  type Station,
  type StationsParams,
  type StationValueElement
} from '@/models'
import { useStatusNotification } from '@/stores/ocpp/statusNotification'
import type { LocationStationStatus } from '@/models/domain/station/component/ListStationsStatus'
import { findLocation } from '@/utils/findLocation'
import {
  ChargePointStatus,
  StationStatus,
  WebSocketStatus as ChargerWsStatus
} from '@/models/ocpp/enums'
import BaseMapContainerLocations from '@/components/ui/BaseMapContainerLocations.vue'
import type { StatusNotificationMapped } from '@/models/ocpp/StatusNotification'
import { StationStatusMap } from '@/models/domain/location/enums'
import AppTopbar from '@/layout/AppTopbar.vue'
import clock from '@/assets/lottie/clock.json'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import { capitalizeString } from '@/utils/capitalize'
import BaseConfirmDeletePopup from '@/components/ui/BaseConfirmDeletePopup.vue'
import { useChargerStatuses } from '@/stores/ocpp/chargerStatuses'
import LocationCreationDialog from '@/components/locations/LocationCreationDialog.vue'
import LocationUploadDialog from '@/components/locations/LocationUploadDialog.vue'
import type { LocationResponse } from '@/models/domain/location/api/Location'

const toast = useToast()
const popup = ref()
const { loading } = storeToRefs(useApi())
const enabled = ref<boolean>(false)
const hideMap = ref<boolean>(false)
const activeCreationDialog = ref<boolean>(false)
const activeUpdateDialog = ref<boolean>(false)
const activeUploadDialog = ref<boolean>(false)
const totalRecords = ref<number>(0)
const locations = ref<Location[]>([])
const updatedLocation = ref<Partial<Location>>()
const updatedLocationId = ref<string>('')
const stations = ref<StationsParams[]>([])
const coords = ref<CoordinateLocation[]>([])
const statusNotificationStore = useStatusNotification()
const locationStationStatus = ref<LocationStationStatus>({})
const rowsPerPage = ref<number>(10)
const currentPage = ref<number>(0)
const chargesStatuses = useChargerStatuses()

const statuses = ref([
  { name: t('status.available'), status: 'available', code: 0 },
  { name: t('status.unavailable'), status: 'unavailable', code: 1 },
  { name: t('status.incidence'), status: 'incidence', code: 2 },
  { name: t('status.disconnected'), status: 'disconnected', code: 3 }
])
const filters = ref({
  active: { value: null, matchMode: FilterMatchMode.IN },
  name: { value: null, matchMode: FilterMatchMode.IN }
})

const handleCreate = () => {
  activeCreationDialog.value = true
}

const handleDetail = (rowClickEvent: DataTableRowClickEvent) => {
  // TODO: TEMP FIX (for primevue: 4.0.7):
  if (
    (rowClickEvent.originalEvent.target as HTMLElement).localName === 'button' ||
    (rowClickEvent.originalEvent.target as HTMLElement).localName === 'svg' ||
    (rowClickEvent.originalEvent.target as HTMLElement).localName === 'path'
  )
    return
  router.push({
    name: RouteNamespace.location,
    params: { id: rowClickEvent['data']['id'] }
  })
}
const addCoord = (location: Location) => {
  const { id, name, lat, lon } = location
  coords.value.push([id as string, name as string, lat as number, lon as number])
}

const addStation = (location: Location) => {
  const locationId = location.id
  location.stations.forEach((station: Station) => {
    const stationId = station.id
    const chargerId = station.charger.id
    const chargerName = station.charger.name
    const statusCharger = station.status
    const existingLocationIndex = stations.value.findIndex(
      (value: StationValueElement) => value[0] === locationId
    )
    if (existingLocationIndex === -1) {
      stations.value.push([
        locationId,
        [
          {
            stationId,
            chargerId,
            chargerName,
            statusCharger
          }
        ]
      ])
    } else {
      stations.value[existingLocationIndex][1].push({
        stationId,
        chargerId,
        chargerName,
        statusCharger
      })
    }
  })
}
const handleUpdate = (row: Location) => {
  updatedLocation.value = {
    address: row.address,
    city: row.city,
    country: row.country,
    description: row.description,
    energySources: row.energySources,
    name: row.name,
    postcode: row.postcode,
    province: row.province,
    organization: row.organization
  }
  updatedLocationId.value = row.id
  activeUpdateDialog.value = true
}
const handleUploadDialog = () => {
  activeUploadDialog.value = true
}
const handleRemoveLocation = async (event: Event, id: string) => {
  popup.value.showConfirmPopup(
    event,
    async () => {
      loading.value = true
      await actionsRemoveLocation(id)
      loading.value = false
    },
    undefined
  )
}
const actionsRemoveLocation = async (id: string) => {
  try {
    const payload = {
      isDeleted: true
    }
    await ApiService.updateEntity(RouteNamespace.locations, id, payload)
    toast.add({
      group: 'success',
      severity: 'success',
      summary: t('detail.station.notifications.deleteSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error deleting station:', error)
  } finally {
    await getLocations()
  }
}
const getLocations = async () => {
  try {
    stations.value = []
    const response = await ApiService.readAllEntities<LocationResponse>(
      `${RouteNamespace.locations}?limit=${rowsPerPage.value}&offset=${currentPage.value}`
    )
    locations.value = response['locations']
    totalRecords.value = response['totalRecords']
    if (coords.value.length !== 0) coords.value = [] //reset coords
    locations.value.forEach(addCoord)
    locations.value.forEach(addStation)
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  }
}
//Prepara el objeto donde se guardaran los estados del cargador
const sortStationsByLocations = (data: Location[]) => {
  let status: ChargePointStatus | StationStatusMap = StationStatusMap.DISCONNECTED
  data.forEach((location: Location) => {
    locationStationStatus.value[location.id] = []
    location.stations.forEach((station: Station) => {
      const statusWebsocket = chargesStatuses.chargerStatuses[station.cpId]
      if (statusWebsocket === ChargerWsStatus.CONNECTED) {
        const statusNotification = statusNotificationStore.statusNotification[`${station.cpId}:0`]
        if (statusNotification) status = statusNotification.status
      }
      locationStationStatus.value[location.id].push({ [station.cpId]: status })
      status = StationStatusMap.DISCONNECTED
    })
  })
}

const getStationsStatus = (newValue: StatusNotificationMapped) => {
  const { cpId, status } = newValue
  const locationId = findLocation(cpId, locations.value)
  if (!locationId) return
  let found = false
  for (let i = 0; i < locationStationStatus.value[locationId].length && !found; i++) {
    if (Object.keys(locationStationStatus.value[locationId][i]).includes(cpId)) {
      locationStationStatus.value[locationId][i][cpId] = StationStatus[status.toUpperCase()]
      found = true
    }
  }
}

const getStatusDisconnection = (cpId: string) => {
  const locationId = findLocation(cpId, locations.value)
  if (!locationId) return
  let found = false
  for (let i = 0; i < locationStationStatus.value[locationId].length && !found; i++) {
    if (Object.keys(locationStationStatus.value[locationId][i]).includes(cpId)) {
      locationStationStatus.value[locationId][i][cpId] = StationStatusMap.DISCONNECTED
      found = true
    }
  }
}

const getStatusConnection = (cpId: string) => {
  let found = false
  const completeStatus = statusNotificationStore.statusNotification[`${cpId}:0`]
  if (!completeStatus) return
  const locationId = findLocation(cpId, locations.value)
  if (!locationId) return
  for (let i = 0; i < locationStationStatus.value[locationId].length && !found; i++) {
    if (Object.keys(locationStationStatus.value[locationId][i]).includes(cpId)) {
      const status = ChargePointStatus[completeStatus.status]
      if (status) locationStationStatus.value[locationId][i][cpId] = status
      found = true
    }
  }
}

const getStationStatusWebsocket = (cpId: string, newValue: ChargerWsStatus) => {
  if (newValue === ChargerWsStatus.CONNECTED) {
    getStatusConnection(cpId)
    return
  }
  getStatusDisconnection(cpId)
}

const onPageChange = (event: PageState) => {
  currentPage.value = event.page
  getLocations()
}
watch(
  () => statusNotificationStore.statusNotification,
  (newStatus) => {
    try {
      for (const key in newStatus) {
        const newValue = newStatus[key]
        if (!newValue) continue
        if (newValue.connectorId !== 0) continue
        getStationsStatus(newValue)
      }
    } catch (err) {
      console.error('Failed to register the new connector status', err)
    }
  },
  { deep: true }
)
watch(
  () => chargesStatuses.chargerStatuses,
  (newStatus) => {
    try {
      for (const key in newStatus) {
        const newValue = newStatus[key]
        if (!newValue) continue
        if (newValue === ChargerWsStatus.CONNECTED) {
          getStationStatusWebsocket(key, newValue)
        } else {
          getStationStatusWebsocket(key, newValue)
        }
      }
    } catch (err) {
      console.error('Failed to register the new websocket status', err)
    }
  },
  { deep: true }
)
onMounted(async () => {
  try {
    loading.value = true
    enabled.value = true
    //open modal dialog from query param
    if (router.currentRoute.value.query.active) {
      activeCreationDialog.value = true
    }
    await Promise.all([getLocations() /*sumConnectorsByUser()*/])
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    loading.value = false
    sortStationsByLocations(locations.value)
  }
})
</script>
<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl">
        <div class="flex flex-row ml-2 justify-content-between align-items-center gap-4">
          <div class="flex">
            <svg-icon name="location" size="24" color="#626868" />
            <span class="font-bold ml-2 text-2xl">{{ t('dashboard.locations') }}</span>
          </div>
          <div class="flex justify-content-center gap-3">
            <Button
              v-tooltip.top="t('detail.location.actions.createBulk')"
              rounded
              class="button button-save shadow-1"
              @click="handleUploadDialog"
            >
              <template #icon>
                <i class="font-bold pi pi-upload text-lg text-white" />
              </template>
            </Button>
            <Button
              v-tooltip.top="t('detail.location.actions.create')"
              rounded
              class="button button-normal shadow-1"
              @click="handleCreate"
            >
              <template #icon>
                <svg-icon name="add" size="20" color="white" />
              </template>
            </Button>
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <div class="flex flex-row gap-4 justify-content-between">
        <div class="flex flex-row gap-2 overflow-hidden">
          <MultiSelect
            v-model="filters['name'].value"
            class="multi-select align-items-center"
            :maxSelectedLabels="3"
            display="chip"
            :placeholder="t('detail.location.header.placeholder.location')"
            :options="locations"
            optionLabel="name"
            optionValue="name"
            :loading
          >
            <template #dropdownicon>
              <svg-icon name="location" size="18" color="#9E9E9E" />
            </template>
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div class="text-color">{{ capitalizeString(slotProps.option.name) }}</div>
              </div>
            </template>
          </MultiSelect>
          <MultiSelect
            v-model="filters['active'].value"
            class="multi-select align-items-center"
            :maxSelectedLabels="4"
            display="chip"
            :placeholder="t('detail.location.header.placeholder.status')"
            :options="statuses"
            optionLabel="name"
            optionValue="code"
          >
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div :class="`status status__${slotProps.option.status} mr-1`" />
                <small
                  class="font-bold"
                  :class="`badgeStatus badgeStatus__${slotProps.option.status}`"
                  >{{ capitalizeString(slotProps.option.name) }}</small
                >
              </div>
            </template>
          </MultiSelect>
        </div>
        <ToggleButton
          v-model="hideMap"
          v-styleclass="{ selector: '.map', enterActiveClass: 'fadein' }"
          class="toggle-button mr-2"
          :onLabel="t('topbar.showMap')"
          :offLabel="t('topbar.hideMap')"
          icon-pos="left"
          onIcon="pi pi-eye"
          offIcon="pi pi-eye-slash"
        />
      </div>
    </template>
  </AppTopbar>
  <section>
    <div class="card h-fit bg-white shadow-none">
      <BaseMapContainerLocations
        v-show="!hideMap"
        :zoom="5"
        :isDraggable="false"
        :coords="coords"
        :stations="stations"
        :stationsStatus="locationStationStatus"
        incidence="3"
      />
      <DataTable
        v-model:filters="filters"
        :value="locations"
        :globalFilterFields="['name', 'active']"
        size="large"
        :rows="rowsPerPage"
        :rowHover="true"
        dataKey="id"
        @rowClick="handleDetail($event)"
        scrollable
      >
        <template #empty>
          <BaseLottieAnimation :icon="clock" :label="t('detail.location.notFound')" />
        </template>
        <Column
          field="name"
          :header="t('detail.location.header.name')"
          header-class="font-bold"
          :showClearButton="true"
          :showFilterMenu="false"
          class="table__name"
        >
          <template #body="{ data }">
            <div class="flex align-items-center gap-2">
              <span>{{ capitalizeString(data.name) }}</span>
            </div>
          </template>
        </Column>
        <Column
          field="address"
          :header="t('detail.location.header.address')"
          header-class="font-bold"
          class="table__address"
        >
          <template #body="slotProps">
            {{ capitalizeString(slotProps.data['address']) }}
          </template>
        </Column>
        <Column
          field="charger"
          :header="t('detail.location.header.chargePoints')"
          header-class="table__header font-bold"
          rowStyleClass="bg-gray-100"
          class="table__chargers"
        >
          <template #body="slotProps">
            <div class="flex flex-row justify-content-center">
              <Tag :value="slotProps.data['stations'].length" rounded />
            </div>
          </template>
        </Column>
        <Column :header="t('detail.header.actions')" header-class="table__header font-bold">
          <template #body="slotProps">
            <div class="flex flex-row justify-content-center">
              <Button
                class="button button-normal mr-2"
                v-tooltip.top="t('detail.location.actions.update')"
                rounded
                @click="handleUpdate(slotProps.data)"
              >
                <template #icon>
                  <svg-icon name="edit" size="18" color="#626868" />
                </template>
              </Button>
              <BaseConfirmDeletePopup ref="popup" />
              <Button
                class="button button-remove"
                v-tooltip.top="t('detail.location.actions.delete')"
                rounded
                @click="handleRemoveLocation($event, slotProps.data.id)"
              >
                <template #icon>
                  <svg-icon name="trash" size="16" />
                </template>
              </Button>
            </div>
          </template>
        </Column>
        <template #footer>
          <div class="flex flex-row justify-content-center align-items-center">
            <Paginator
              @page="onPageChange"
              class="flex justify-content-center"
              :rows="rowsPerPage"
              :totalRecords="totalRecords"
              :pt="{
                pageButton: ({ props, state, context }) => ({
                  class: context.active ? 'bg-gray-500 text-white' : undefined
                })
              }"
            />
          </div>
        </template>
      </DataTable>
    </div>
    <LocationCreationDialog
      v-model:visible="activeCreationDialog"
      :toasting="toast"
      @refresh-locations="getLocations()"
    />
    <LocationCreationDialog
      v-model:visible="activeUpdateDialog"
      updating
      :updated-location="updatedLocation"
      :updated-location-id="updatedLocationId"
      :toasting="toast"
      @refresh-locations="getLocations()"
    />
    <LocationUploadDialog
      v-model:visible="activeUploadDialog"
      :toasting="toast"
      @refresh-locations="getLocations()"
    />
    <BasePushNotification group="success" icon-name="success" color="#00DB7F" />
    <BasePushNotification group="error" icon-name="error" color="#EA2839" />
  </section>
</template>

<style scoped>
::v-deep(.table__header) > div > span {
  margin: 0 auto;
}

::v-deep(.table__name) {
  width: 27%;
}

::v-deep(.table__address) {
  width: 42%;
}

::v-deep(.table__chargers) {
  width: 15%;
}

::v-deep(.table__actions) {
  width: 16%;
}

::v-deep(.multi-select) {
  min-width: 11rem;
}

::v-deep(.toggle-button) {
  min-width: 11rem;
  min-height: 40px;
}
</style>
