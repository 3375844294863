<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { svgIcons } from '@/plugins/svg-icon-loader'

interface svgIconProps {
  name: string | ''
  size?: string
  color?: string
}

const props = defineProps<svgIconProps>()

const customClass = computed(() => {
  return `connect-icon-${props.name}`
})

const iconComponent = computed(() => {
  return `svg-icon-${props.name}`
})

onMounted(() => {
  if (svgIcons.indexOf(props.name) === -1) {
    console.warn(props.name + '.svg not found!!')
  }
})
</script>
<template>
  <component
    :is="iconComponent"
    :width="size"
    :height="size"
    :style="{ color: color }"
    :class="customClass"
    v-bind="$attrs"
  ></component>
</template>
