<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useFieldArray, useForm } from 'vee-validate'
import { array, boolean, number, object, string } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { t } from '@/common/i18n'
import { useApi } from '@/stores/api/api'
import { useAuthStore } from '@/stores/auth'
import { ApiService } from '@/services/ApiService'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import FooterDialog from '@/components/partials/FooterDialog.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import type { ToastServiceMethods } from 'primevue/toastservice'
import {
  type ChargerModel,
  type ConnectorsUpdateDTO,
  type Coordinate,
  type Rate,
  type StationCoords,
  type StationUpdateDTO
} from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { capitalizeString } from '@/utils/capitalize'
import BaseMapContainer from '@/components/ui/BaseMapContainer.vue'
import BaseInputNumber from '@/components/ui/BaseInputNumber.vue'

const {
  updatedStation,
  updatedStationId,
  stationStatus,
  updating,
  locationId,
  locationCoords,
  toasting
} = defineProps<{
  updatedStation?: StationUpdateDTO
  updatedStationId?: string
  stationStatus?: number
  updating?: boolean
  locationId?: string
  locationCoords?: [number, number]
  toasting: ToastServiceMethods
}>()

const visible = defineModel<boolean>('visible')

const emit = defineEmits<{
  (e: 'refreshStations'): void
}>()

const { loading, chargers, connectorTypes } = storeToRefs(useApi())
const { userId } = useAuthStore()

// TODO: Error messages i18n
const schema = toTypedSchema(
  object({
    alias: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.required')),
    chargerId: string()
      .uuid()
      .required(() => t('validation.required')),
    connectors: array()
      .of(
        object({
          id: string().uuid().optional(),
          connectorTypeId: string()
            .uuid(() => t('validation.required'))
            .required(() => t('validation.required')),
          maxPower: number()
            .min(1.6, ({ min }) => t('validation.minKWH', { min }))
            .required(() => t('validation.required'))
        })
      )
      .min(1)
      .default([{ connectorTypeId: '', maxPower: 1.6 }]),
    lat: number().required(() => t('validation.required')),
    lon: number().required(() => t('validation.required')),
    paidService: boolean()
      .required(() => t('validation.required'))
      .default(false),
    rateId: string().uuid().optional().nullable(),
    serialNumber: string()
      .max(25, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.required'))
  })
)

const { defineField, handleSubmit, resetForm, errors, meta } = useForm({
  validationSchema: schema
})

const [alias] = defineField('alias')
const [chargerId] = defineField('chargerId')
const { fields, push, remove } = useFieldArray<ConnectorsUpdateDTO>('connectors')
const [lat] = defineField('lat')
const [lon] = defineField('lon')
const [paidService] = defineField('paidService')
const [rateId] = defineField('rateId')
const [serialNumber] = defineField('serialNumber')

const prevNumberConnections = ref(1)
const numberConnections = ref(1)

const coords = ref<Coordinate[]>([])
const rates = ref<Rate[]>([])
const updateCoords = (newCoords: StationCoords) => {
  lat.value = newCoords.lat
  lon.value = newCoords.lng
}

const getRates = async () => {
  try {
    rates.value = await ApiService.readAllEntities(RouteNamespace.rates)
  } catch (error) {
    console.error('Error retrieving rates:', error)
  }
}

const pushConnector = () => {
  if (numberConnections.value > prevNumberConnections.value) {
    push({ connectorTypeId: '', maxPower: 1.6 })
    prevNumberConnections.value++
  } else if (numberConnections.value < prevNumberConnections.value) {
    remove(numberConnections.value)
    prevNumberConnections.value--
  }
}

const onSubmit = handleSubmit(async (values) => {
  try {
    loading.value = true
    if (updatedStationId) {
      await ApiService.updateEntity(RouteNamespace.stations, updatedStationId, {
        ...values,
        cpId: values.serialNumber
      })
    } else {
      await ApiService.createEntity(RouteNamespace.stations, {
        ...values,
        cpId: values.serialNumber,
        userId,
        locationId
      })
    }
    toasting.add({
      group: 'success',
      severity: 'success',
      summary: updating
        ? t('detail.station.notifications.updateSuccess')
        : t('detail.station.notifications.createSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
    toasting.add({
      group: 'error',
      severity: 'error',
      summary: updating
        ? t('detail.station.notifications.updateError')
        : t('detail.station.notifications.createError'),
      life: 3000
    })
  } finally {
    visible.value = false
    emit('refreshStations')
    loading.value = false
  }
})
const handleCancel = () => {
  visible.value = false
}
watch(visible, () => {
  resetForm({
    values: updatedStation
      ? {
          ...updatedStation
        }
      : {
          lat: locationCoords?.[0],
          lon: locationCoords?.[1]
        }
  })

  numberConnections.value = updatedStation?.connectors?.length || 1
  prevNumberConnections.value = updatedStation?.connectors?.length || 1
})
onMounted(async () => {
  await getRates()
})
</script>

<template>
  <BaseDialog
    v-model:visible="visible"
    :closable="false"
    :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
  >
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-4 ml-3">
        <div v-if="updatedStation" class="flex flex-row">
          <p class="p-dialog-title mr-1">{{ t('detail.header.updateChargePoint') }} -</p>
          <p class="p-dialog-title font-family-light font-italic">
            {{ capitalizeString(updatedStation['alias'] ?? '') }}
          </p>
        </div>
        <p v-else class="p-dialog-title">{{ t('detail.header.addChargePoint') }}</p>
      </div>
    </template>
    <template #header>
      <div class="absolute top-0 right-0 mt-4 mr-3">
        <svg-icon name="station-white" size="24" color="white" />
      </div>
    </template>
    <template #body>
      <div class="flex flex-column">
        <div class="flex flex-row justify-content-between">
          <div class="field mt-2 col-4">
            <label class="font-family-light required" for="serialNumber">{{
              t('detail.station.dialog.serialNumber')
            }}</label>
            <InputText
              v-model="serialNumber"
              id="serialNumber"
              aria-describedby="serialNumber-help"
              type="text"
              :invalid="!!errors.serialNumber"
              :placeholder="t('detail.station.dialog.placeholder.serialNumber')"
            />
            <small id="serialNumber-help" class="p-error">
              {{ errors.serialNumber }}
            </small>
          </div>
          <div class="field mt-2 col-5">
            <label class="font-family-light required" for="alias">{{
              t('detail.station.dialog.name')
            }}</label>
            <IconField icon-position="left">
              <InputIcon>
                <svg-icon name="location-name" size="18" color="#9E9E9E" />
              </InputIcon>
              <InputText
                v-model="alias"
                id="alias"
                aria-describedby="alias-help"
                type="text"
                :invalid="!!errors.alias"
                :placeholder="t('detail.station.dialog.placeholder.name')"
              />
            </IconField>
            <small id="alias-help" class="p-error">
              {{ errors.alias }}
            </small>
          </div>
          <div class="field col-3">
            <label class="font-family-light" for="paidService">{{
              t('detail.station.dialog.paidService')
            }}</label>
            <ToggleSwitch
              v-model="paidService"
              id="paidService"
              aria-describedby="paidService-help"
              :invalid="!!errors.paidService"
            />
            <small id="paidService-help" class="p-error">
              {{ errors.paidService }}
            </small>
          </div>
        </div>
        <div class="flex flex-row justify-content-between">
          <div class="field mb-3 col-4">
            <label class="font-family-light required" for="chargerId">{{
              t('detail.station.dialog.model')
            }}</label>
            <Select
              v-model="chargerId"
              id="chargerId"
              aria-describedby="chargerId-help"
              optionValue="id"
              optionLabel="name"
              :options="
                chargers.map((charger: ChargerModel) => ({
                  ...charger,
                  name: capitalizeString(charger['name'])
                }))
              "
              :emptyMessage="t('detail.station.dialog.placeholder.notFoundModel')"
              :invalid="!!errors.chargerId"
              :placeholder="t('detail.station.dialog.placeholder.model')"
              :pt="{
                item: ({ props, state, context }) => ({
                  class: context.selected
                    ? 'bg-gray-300'
                    : context.focused
                      ? 'bg-gray-100'
                      : undefined
                })
              }"
            >
              <template #dropdownicon>
                <div class="flex flex-column justify-content-center p-0 col-12">
                  <svg-icon name="arrow-down" size="18" color="#E9E9E9" />
                </div>
              </template>
            </Select>
            <small id="chargerId-help" class="p-error">
              {{ errors.chargerId }}
            </small>
          </div>
          <div class="field mb-3 col-5">
            <label class="font-family-light" for="rateId">{{
              t('detail.station.dialog.rate')
            }}</label>
            <Select
              v-model="rateId"
              id="rateId"
              aria-describedby="rateId-help"
              optionValue="id"
              optionLabel="alias"
              :options="
                Array.isArray(rates['rates'])
                  ? rates['rates'].map((rate: Rate) => ({
                      ...rate,
                      alias: capitalizeString(rate['alias'])
                    }))
                  : []
              "
              :emptyMessage="t('detail.station.dialog.placeholder.notFoundRates')"
              :invalid="!!errors.rateId"
              :placeholder="t('detail.station.dialog.placeholder.rate')"
              :pt="{
                item: ({ context }) => ({
                  class: context.selected
                    ? 'bg-gray-300'
                    : context.focused
                      ? 'bg-gray-100'
                      : undefined
                })
              }"
            >
              <template #dropdownicon>
                <div class="flex flex-column justify-content-center p-0 col-12">
                  <svg-icon name="arrow-down" size="18" color="#E9E9E9" />
                </div>
              </template>
            </Select>
            <small id="rateId-help" class="p-error">
              {{ errors.rateId }}
            </small>
          </div>
          <div class="field mb-3 col-3">
            <label class="font-family-light" for="numberConnections">{{
              t('detail.station.dialog.connector')
            }}</label>
            <BaseInputNumber
              v-model="numberConnections"
              id="numberConnections"
              aria-describedby="numberConnections-help"
              :min="1"
              :max="3"
              :onInput="pushConnector"
              :pt="{
                pcInputText: {
                  root: {
                    disabled: true
                  }
                }
              }"
            />
          </div>
        </div>
        <div class="flex flex-row justify-content-between mb-2">
          <div class="col-5 pb-0">
            <label class="font-family-light required" for="connectorTypeId">{{
              t('detail.station.dialog.connectorType')
            }}</label>
          </div>
          <div class="col-5 pb-0">
            <label class="font-family-light required" for="maxPower">{{
              t('detail.card.dialog.limitKWH')
            }}</label>
          </div>
        </div>
        <div
          v-for="(field, idx) in fields"
          :key="field.key"
          class="flex flex-row justify-content-between relative"
        >
          <div class="field mb-3 col-5 pt-0 relative">
            <Select
              v-model="field.value.connectorTypeId"
              id="connectorTypeId"
              :aria-describedby="`connectorTypeId-help${idx}`"
              optionValue="id"
              optionLabel="connector"
              :options="connectorTypes"
              :emptyMessage="t('detail.station.dialog.placeholder.notFoundModel')"
              :invalid="!!errors['connector[idx].connectorTypeId']"
              :placeholder="t('detail.station.dialog.placeholder.connectorType')"
              :pt="{
                item: ({ context }) => ({
                  class: context.selected
                    ? 'bg-gray-300'
                    : context.focused
                      ? 'bg-gray-100'
                      : undefined
                })
              }"
            >
              <template #dropdownicon>
                <div class="flex flex-column justify-content-center p-0 col-12">
                  <svg-icon name="arrow-down" size="18" color="#E9E9E9" />
                </div>
              </template>
              <template #option="slotProps">
                <div class="flex flex-row justify-content-between">
                  <p>{{ slotProps.option.connector.toUpperCase() }}</p>
                </div>
              </template>
            </Select>
            <small :id="`connectorTypeId-help${idx}`" class="p-error absolute">
              {{ errors[`connectors[${idx}].connectorTypeId`] }}
            </small>
          </div>
          <div class="field mb-3 col-5 pt-0 relative">
            <BaseInputNumber
              v-model="field.value.maxPower"
              id="maxPower"
              :aria-describedby="`maxPower-help${idx}`"
              showButtons
              suffix=" kWh"
              :min="1.6"
              :step="0.1"
              :invalid="!!errors['connector[idx].maxPower']"
              :placeholder="t('detail.station.dialog.placeholder.insertKWH')"
            />
            <small :id="`maxPower-help${idx}`" class="p-error absolute">
              {{ errors[`connectors[${idx}].maxPower`] }}
            </small>
          </div>
        </div>
        <div class="flex flex-row justify-content-between">
          <div class="field col-12">
            <label class="font-family-light required" for="coords">{{
              t('detail.station.dialog.location')
            }}</label>
            <BaseMapContainer
              :coordsCharger="
                updating
                  ? [lat ?? 0, lon ?? 0, stationStatus ?? 0]
                  : locationCoords
                    ? [locationCoords[0], locationCoords[1], 0]
                    : undefined
              "
              :zoom="16"
              height="35vh"
              incidence="3"
              :coords="coords"
              @update="updateCoords"
            />
            <small id="coords-help" class="p-error">
              {{ errors.lat }}
            </small>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
        <FooterDialog @cancel="handleCancel" @confirm="onSubmit" remove :disabled="!meta.valid" />
      </div>
    </template>
  </BaseDialog>
</template>

<style scoped>
::v-deep(.p-error) {
  left: 1rem;
  bottom: 0;
}
</style>
