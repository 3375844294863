import { defineStore } from 'pinia'
import { socket } from '@/config/socket'
import { TopicNameSpace } from '@/models/common/TopicNameSpace'
import type { WebSocketStatus } from '@/models/ocpp/enums'
import type { ChargerStatus } from '@/models/ocpp/Broadcast'

export const useChargerStatuses = defineStore('chargerStatuses', {
  state: () => ({
    chargerStatuses: {} as { [key: string]: WebSocketStatus }
  }),
  getters: {
    getChargerStatuses: (state) => state.chargerStatuses,
    getChargerStatusesByCpId: (state) => (cpId: string) => {
      return state.chargerStatuses[cpId]
    }
  },

  actions: {
    bindEvents() {
      socket.on(TopicNameSpace.chargerStatus, (data: ChargerStatus) => {
        const { cpId, status } = data
        this.setChargerStatuses(cpId, status)
      })
    },

    setChargerStatuses(cpId: string, status: WebSocketStatus) {
      this.chargerStatuses[cpId] = status
    }
  }
})
