import { defineStore } from 'pinia'
import { socket } from '@/config/socket'
import type { StatusNotificationBroadcast } from '@/models/ocpp/Broadcast'
import type {
  StatusNotificationMapped,
  StatusNotificationRequest
} from '@/models/ocpp/StatusNotification'
import { TopicNameSpace } from '@/models/common/TopicNameSpace'

export const useStatusNotification = defineStore('statusNotification', {
  state: () => ({
    statusNotification: {} as { [key: string]: StatusNotificationMapped }
  }),
  getters: {
    getStatusNotification: (state) => state.statusNotification,
    getStatusNotificationByKey: (state) => (key: string) => {
      return state.statusNotification[key]
    },
    getStatusNotificationByKeys: (state) => (keys: string[]) => {
      return keys.map((k) => state.statusNotification[k])
    },
    getStatusNotificationByConnector: (state) => (cpId: string, connector: number) => {
      return state.statusNotification[`${cpId}:${connector}`]
    }
  },
  actions: {
    bindEvents() {
      socket.on(TopicNameSpace.statusNotification, (data: StatusNotificationBroadcast) => {
        const { cpId, payload } = data
        const connectorId = payload.connectorId
        this.setStatusNotification(cpId, connectorId, payload)
      })
    },
    setStatusNotification(
      cpId: string,
      connectorId: number,
      statusNotification: StatusNotificationRequest
    ) {
      this.statusNotification[`${cpId}:${connectorId}`] = { ...statusNotification, cpId }
    }
  }
})
