import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext,
  type RouteLocationNormalized
} from 'vue-router'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import routes from '@/router/routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

// redirect to login page if not logged in and trying to access a restricted page
router.beforeEach(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const isAuth = sessionStorage.getItem('accessToken')
    if (to.meta.requiresAuth && !isAuth) {
      next({ name: RouteNamespace.login })
    } else next()
  }
)
export default router
