<script setup lang="ts"></script>

<template>
  <ToggleSwitch
    input-class="active-switch"
    :pt="{
      slider: ({ props }) => ({
        class: props.modelValue ? 'active' : 'inactive'
      })
    }"
  />
</template>

<style scoped lang="scss">
:deep(.active) {
  background: var(--darkGreen) !important;
}

:deep(.inactive) {
  background: var(--red) !important;
}
</style>
