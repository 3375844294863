<script setup lang="ts">
import { computed, watch } from 'vue'
import { useForm, useIsFieldDirty, useIsFieldValid } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/yup'
import { object, string } from 'yup'
import { storeToRefs } from 'pinia'
import type { FileUploadUploaderEvent } from 'primevue/fileupload'
import type { ToastServiceMethods } from 'primevue/toastservice'
import { useApi } from '@/stores/api/api'
import { useAuthStore } from '@/stores/auth'
import { t } from '@/common/i18n'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import { ApiService } from '@/services/ApiService'
import { Roles } from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace'

const { toasting } = defineProps<{
  toasting: ToastServiceMethods
}>()

const emit = defineEmits<{
  (e: 'refreshLocations'): void
}>()

const visible = defineModel<boolean>('visible')

const { loading, organizationEmails } = storeToRefs(useApi())
const {
  role: { name: role },
  organizations: userOrganizations
} = useAuthStore()

const schema = toTypedSchema(
  object({
    organizationId: string()
      .uuid()
      .when((_, schema) =>
        role === Roles.admin || (role === Roles.manager && userOrganizations.length > 1)
          ? schema.required(() => t('validation.required')).default('')
          : schema.notRequired()
      )
  })
)

const { defineField, handleSubmit, resetForm, errors } = useForm({
  validationSchema: schema
})

const [organizationId] = defineField('organizationId')

const isOrganizationIdValid = useIsFieldValid('organizationId')
const isOrganizationIdDirty = useIsFieldDirty('organizationId')
const uploadDisabled = computed(() => {
  switch (role) {
    case Roles.admin:
      return !isOrganizationIdValid.value || !isOrganizationIdDirty.value
    case Roles.manager:
      return userOrganizations.length > 1
        ? !isOrganizationIdValid.value || !isOrganizationIdDirty.value
        : false
    default:
      return false
  }
})

const handleUploadCSV = (event: FileUploadUploaderEvent) => {
  handleSubmit(async (values) => {
    try {
      loading.value = true

      const formData = new FormData()
      formData.append('file', event.files[0])
      formData.append(
        'organizationId',
        values.organizationId ? values.organizationId : userOrganizations[0].id
      )

      await ApiService.createEntity(RouteNamespace.uploadCSVLocations, formData)

      toasting.add({
        group: 'success',
        severity: 'success',
        summary: t('detail.location.notifications.createSuccess'),
        life: 3000
      })
      emit('refreshLocations')
    } catch (error) {
      console.error('Error handling upload:', error)
      toasting.add({
        group: 'error',
        severity: 'error',
        summary: t('detail.location.notifications.createError'),
        life: 3000
      })
    } finally {
      visible.value = false
      loading.value = false
    }
  })()
}

watch(visible, () => {
  resetForm()
})
</script>

<template>
  <BaseDialog
    v-model:visible="visible"
    :closable="false"
    :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
  >
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-5 ml-4">
        <p class="p-dialog-title">{{ t('detail.location.actions.createBulk') }}</p>
      </div>
    </template>
    <template #header>
      <div class="absolute top-0 right-0 mt-4 mr-3">
        <svg-icon name="location" size="24" color="white" />
      </div>
    </template>
    <template #body>
      <div
        v-if="role === Roles.admin || (role === Roles.manager && userOrganizations.length > 1)"
        class="flex flex-row"
      >
        <div class="field col-12">
          <label for="organizationId" class="font-family-light required">{{
            t('detail.location.dialog.owner')
          }}</label>
          <Select
            v-model="organizationId"
            id="organizationId"
            aria-describedby="organizationId-help"
            class="h-3rem align-items-center"
            filter
            optionLabel="email"
            optionValue="id"
            :options="role === Roles.admin ? organizationEmails : userOrganizations"
            :invalid="!!errors.organizationId"
            :emptyMessage="t('detail.organization.notFound')"
            :placeholder="t('detail.location.dialog.placeholder.owner')"
            :pt="{
              item: ({ context }) => ({
                class: context.selected
                  ? 'bg-gray-300'
                  : context.focused
                    ? 'bg-gray-100'
                    : undefined
              })
            }"
          >
            <template #dropdownicon>
              <div class="flex flex-column justify-content-center p-0 col-12">
                <svg-icon name="arrow-down" size="18" color="#9E9E9E" />
              </div>
            </template>
          </Select>
          <small id="organizationId-help" class="p-error">
            {{ errors.organizationId }}
          </small>
        </div>
      </div>
      <div class="field col-12">
        <FileUpload
          accept=".csv"
          customUpload
          name="file"
          url="/api/upload"
          :maxFileSize="1000000"
          :fileLimit="1"
          :disabled="uploadDisabled"
          :previewWidth="0"
          :cancelLabel="t('actions.cancel')"
          :chooseLabel="t('actions.import')"
          :uploadLabel="t('actions.upload')"
          :pcFileBadge="t('actions.upload')"
          @uploader="handleUploadCSV"
        />
      </div>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
::v-deep(.p-fileupload .p-fileupload-file) {
  border: none !important;
}
</style>
