import { io } from 'socket.io-client'
import { reactive } from 'vue'

export const state = reactive({
  connected: false
})
const token = sessionStorage.getItem('accessToken') || ''
export const socket = io(import.meta.env.VITE_WS, {
  autoConnect: false, // it is necessary to set this to false, otherwise it will connect automatically preventing the token to be set
  reconnection: true,
  reconnectionAttempts: 9, //The first reconnection attempt does not count because it is the default, so we always have to put one less than we want
  reconnectionDelay: 15000,
  reconnectionDelayMax: 15000,
  extraHeaders: {
    authentication: token //transport is polling, so it is necessary to set the token in the headers
  }
})

let reconnectAttempts = 0

socket.on('connect', () => {
  state.connected = true
  console.log(`🌐 Websocket connected.`)
  reconnectAttempts = 0
})

socket.on('disconnect', (reason, details) => {
  if (reason === 'io server disconnect' || reason === 'io client disconnect') {
    state.connected = false
  }
  console.log('Error reason: ', reason)
  console.log('Description error: ', details)
})

socket.on('connect_error', (error) => {
  console.log(`${error.name}: ${error.message}`)
  if (socket.active) {
    state.connected = false
    reconnectAttempts++
    console.log(
      `Trying reconnect. Status connected: ${state.connected}. N_Max_Attempt: ${socket.io.reconnectionAttempts() + 1} Attempt: ${reconnectAttempts}.`
    )
  } else {
    console.log(error.message)
    socket.connect()
    state.connected = true
  }
})
