import { defineStore } from 'pinia'
import { socket } from '@/config/socket'
import type { Transaction } from '@/models'

export const useTransactions = defineStore('transactions', {
  state: () => ({
    transactions: [] as Transaction[]
  }),
  actions: {
    bindEvents() {
      socket.on('new-transaction', (transaction: Transaction) => {
        this.addTransaction(transaction)
      })
    },
    addTransaction(transaction: Transaction) {
      this.transactions.push(transaction)
    },
    setTransactions(transactions: Transaction[]) {
      this.transactions = transactions
    }
  }
})
