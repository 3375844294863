<script setup lang="ts">
import { onMounted, ref } from 'vue'
import router from '@/router'
import { i18n, t } from '@/common/i18n'
import { FilterMatchMode } from '@primevue/core/api'
import type { PageState } from 'primevue/paginator'
import { useToast } from 'primevue/usetoast'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { useApi } from '@/stores/api/api'
import { storeToRefs } from 'pinia'
import { ApiService } from '@/services/ApiService'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import BaseConfirmDeletePopup from '@/components/ui/BaseConfirmDeletePopup.vue'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import { capitalizeString } from '@/utils/capitalize'
import clock from '@/assets/lottie/clock.json'
import AppTopbar from '@/layout/AppTopbar.vue'
import BaseBadge from '@/components/ui/BaseBadge.vue'
import UserCreationDialog from '@/components/users/UserCreationDialog.vue'
import { type Role, Roles, type User } from '@/models'
import type { UserResponse } from '@/models/domain/user/api/User'
import { useAuthStore } from '@/stores/auth'

const { loading } = storeToRefs(useApi())
const { role: userRole, email: userEmail } = storeToRefs(useAuthStore())

const activeCreationDialog = ref<boolean>(false)
const activeUpdateDialog = ref<boolean>(false)
const popup = ref()

const updatedUser = ref<Partial<User>>()
const updatedUserId = ref<string>('')

const filters = ref({
  global: { value: undefined, matchMode: FilterMatchMode.CONTAINS }
})
const users = ref<User[]>([])
const totalRecords = ref<number>(0)
const rowsPerPage = ref<number>(10)
const currentPage = ref<number>(0)
const toast = useToast()

const getUsers = async () => {
  try {
    loading.value = true
    const response = await ApiService.readAllEntities<UserResponse>(
      `${RouteNamespace.users}?limit=${rowsPerPage.value}&offset=${currentPage.value}`
    )
    users.value = response['users']
    totalRecords.value = response['totalRecords']
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
    users.value = []
    totalRecords.value = 0
  } finally {
    loading.value = false
  }
}

const handleSendMail = async (user: User) => {
  try {
    await ApiService.sendPasswordMail(`${RouteNamespace.mailPassword}/${user.id}`)
    toast.add({
      group: 'success',
      severity: 'success',
      summary: i18n.global.t('detail.user.notifications.sentMail'),
      life: 3000
    })
  } catch (error) {
    toast.add({
      group: 'error',
      severity: 'error',
      summary: i18n.global.t('notifications.error'),
      life: 3000
    })
    console.error('Error occurred refreshing customer:', error)
  }
}

const handleCreate = () => {
  activeCreationDialog.value = true
}

const handleUpdate = async (row: User) => {
  updatedUser.value = {
    active: row.active,
    email: row.email,
    name: row.name,
    organizations: row.organizations,
    phone: row.phone,
    role: row.role,
    surname: row.surname
  }
  updatedUserId.value = row.id
  activeUpdateDialog.value = true
}

const actionsRemoveUser = async (user: string) => {
  try {
    await ApiService.deleteEntity(`${RouteNamespace.users}/${user['id']}`)
    toast.add({
      group: 'success',
      severity: 'success',
      summary: t('detail.user.notifications.deleteSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    await getUsers()
  }
}

const handleRemove = async (event: Event, row: string) => {
  popup.value.showConfirmPopup(
    event,
    async () => {
      loading.value = true
      await actionsRemoveUser(row)
      loading.value = false
    },
    undefined
  )
}

const onPageChange = (event: PageState) => {
  currentPage.value = event.page
  getUsers()
}

const disableUserActions = (
  ownRole: string,
  ownEmail: string,
  tableRole: Role,
  tableEmail: string
) => {
  if (ownEmail === tableEmail) return true
  switch (ownRole) {
    case Roles.admin:
      return false
    case Roles.manager:
      return tableRole.name !== Roles.owner && tableRole.name !== Roles.viewer
    case Roles.owner:
      return tableRole.name !== Roles.viewer
    default:
      return true
  }
}

onMounted(async () => {
  try {
    loading.value = true
    totalRecords.value = users.value.length
    //open modal dialog from query param
    if (router.currentRoute.value.query.active) {
      activeCreationDialog.value = true
    }
    await Promise.all([getUsers()])
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    loading.value = false
  }
})
</script>
<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl">
        <div class="flex flex-row ml-2 justify-content-between align-items-center">
          <div class="flex">
            <svg-icon name="user-face" size="24" color="#626868" />
            <span class="font-bold ml-2 text-2xl">{{ t('dashboard.users') }}</span>
          </div>
          <Button
            v-tooltip.top="t('detail.user.actions.create')"
            class="button button-normal ml-3"
            rounded
            @click="handleCreate"
          >
            <template #icon>
              <svg-icon name="add" size="18" color="#626868" />
            </template>
          </Button>
        </div>
      </div>
    </template>
    <template #body>
      <div class="flex w-3">
        <IconField icon-position="left">
          <InputIcon class="pi pi-search"></InputIcon>
          <InputText
            v-model="filters['global'].value"
            :placeholder="t('search')"
            type="text"
            :pt="{
              root: {
                class: ['border-300']
              }
            }"
          />
        </IconField>
      </div>
    </template>
  </AppTopbar>
  <div class="card h-fit bg-white shadow-none">
    <DataTable
      v-model:filters="filters"
      :value="users"
      :globalFilterFields="['name', 'surname', 'email', 'phone', 'card.alias']"
      :rows="rowsPerPage"
      dataKey="id"
      scrollable
    >
      <template #empty>
        <BaseLottieAnimation :icon="clock" :label="t('detail.user.notFound')" />
      </template>
      <Column
        field="name"
        :header="t('detail.user.header.name')"
        header-class="font-bold"
        class="table__name"
      >
        <template #body="slotProps">
          {{ capitalizeString(slotProps.data['name']) }}
          {{ capitalizeString(slotProps.data['surname']) }}
        </template>
      </Column>
      <Column
        field="email"
        :header="t('detail.user.header.email')"
        header-class="font-bold"
        class="table__email"
      >
        <template #body="slotProps">
          {{ slotProps.data['email'] }}
        </template>
      </Column>
      <Column
        field="status"
        :header="t('detail.user.header.status')"
        header-class="font-bold"
        class="table__status"
      >
        <template #body="slotProps">
          <BaseBadge
            rounded
            outlined
            :content="slotProps.data['active'] === true ? t('status.active') : t('status.inactive')"
            :style-content="`status status__${slotProps.data['active'] === true ? 'available' : 'disabled'}`"
            :style-header="`badgeStatus badgeStatus__${slotProps.data['active'] === true ? 'available' : 'disabled'}`"
          />
        </template>
      </Column>
      <Column
        field="energy_supplied"
        :header="t('detail.user.header.energy_supplied')"
        header-class="font-bold"
        class="table__energy"
      >
        <template #body>
          {{ `${(Math.random() * 10).toFixed(2)} kWh` }}
        </template>
      </Column>
      <Column
        field="rfidCards"
        :header="t('detail.user.header.rfidCards')"
        header-class="font-bold"
        class="table__card"
      >
        <template #body="slotProps">
          {{ slotProps.data.card ? t('yes') : t('no') }}
        </template>
      </Column>
      <Column
        field="nameRfidCard"
        :header="t('detail.user.header.nameRfidCard')"
        header-class="font-bold"
        class="table__card-name"
      >
        <template #body="slotProps">
          {{ slotProps.data['card']?.['alias'] || '---' }}
        </template>
      </Column>
      <Column
        :header="t('detail.header.actions')"
        header-class="table__header font-bold"
        class="table__actions"
      >
        <template #body="slotProps">
          <div class="flex flex-row justify-content-center">
            <Button
              class="button button-normal mr-2"
              v-tooltip.top="t('detail.user.actions.update')"
              :disabled="
                disableUserActions(
                  userRole.name,
                  userEmail,
                  slotProps.data.role,
                  slotProps.data.email
                )
              "
              rounded
              @click="handleUpdate(slotProps.data)"
            >
              <template #icon>
                <svg-icon name="edit" size="18" color="#626868" />
              </template>
            </Button>
            <Button
              v-if="userRole.name === Roles.admin"
              v-tooltip.top="t('detail.user.actions.activation')"
              rounded
              class="button button-warning mr-2"
              @click="handleSendMail(slotProps.data)"
            >
              <template #icon>
                <svg-icon name="mail" size="16" color="#626868" />
              </template>
            </Button>
            <BaseConfirmDeletePopup ref="popup" />
            <Button
              v-tooltip.top="t('detail.user.actions.delete')"
              icon="pi pi-trash"
              :disabled="
                disableUserActions(
                  userRole.name,
                  userEmail,
                  slotProps.data.role,
                  slotProps.data.email
                )
              "
              rounded
              class="button button-remove"
              @click="handleRemove($event, slotProps.data)"
            >
              <template #icon>
                <svg-icon name="trash" size="16" />
              </template>
            </Button>
          </div>
        </template>
      </Column>
      <template #footer>
        <Paginator
          @page="onPageChange"
          class="flex justify-content-center"
          :rows="rowsPerPage"
          :totalRecords
          :pt="{
            pageButton: ({ props, state, context }) => ({
              class: context.active ? 'bg-gray-500 text-white' : undefined
            })
          }"
        />
      </template>
    </DataTable>
  </div>
  <UserCreationDialog
    v-model:visible="activeCreationDialog"
    :toasting="toast"
    @refresh-users="getUsers"
  />
  <UserCreationDialog
    v-model:visible="activeUpdateDialog"
    updating
    :updated-user="updatedUser"
    :updated-user-id="updatedUserId"
    :toasting="toast"
    @refresh-users="getUsers"
  />
  <BasePushNotification group="success" icon-name="success" color="#00DB7F" />
  <BasePushNotification group="error" icon-name="error" color="#EA2839" />
</template>
<style scoped lang="scss">
::v-deep(.p-inputswitch ::before) {
  background-color: var(--white) !important;
}

::v-deep(.p-inputswitch-checked ::before) {
  background-color: var(--action-activate) !important;
}

::v-deep(.table__header) > div > span {
  margin: 0 auto;
}

::v-deep(.table__name) {
  width: 14%;
}

::v-deep(.table__email) {
  width: 24%;
}

::v-deep(.table__status) {
  width: 12%;
}

::v-deep(.table__energy) {
  width: 13%;
}

::v-deep(.table__card) {
  width: 8%;
}

::v-deep(.table__card-name) {
  width: 12%;
}

::v-deep(.table__actions) {
  width: 17%;
}
</style>
