<script setup lang="ts">
import { toRefs } from 'vue'

const props = defineProps<{
  header?: string
  closable?: boolean
}>()

const visible = defineModel<boolean>('visible')

const emit = defineEmits(['hide'])

const { header } = toRefs(props)
</script>

<template>
  <Fluid>
    <Dialog
      v-model:visible="visible"
      v-bind="$attrs"
      modal
      :closable="closable"
      :header="header"
      dismissable-mask
      @hide="emit('hide')"
    >
      <div>
        <slot name="title"></slot>
        <slot name="header"></slot>
        <slot name="body"></slot>
        <slot name="footer"></slot>
      </div>
    </Dialog>
  </Fluid>
</template>
