<script setup lang="ts">
import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useForm } from 'vee-validate'
import { array, boolean, object, string } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import router from '@/router'
import { t } from '@/common/i18n'
import { useApi } from '@/stores/api/api'
import { ApiService } from '@/services/ApiService'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import FooterDialog from '@/components/partials/FooterDialog.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import { capitalizeString } from '@/utils/capitalize'
import type { ToastServiceMethods } from 'primevue/toastservice'
import { Roles, type User } from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { useAuthStore } from '@/stores/auth'

const { updatedUser, updatedUserId, updating, toasting } = defineProps<{
  updatedUser?: Partial<User>
  updatedUserId?: string
  updating?: boolean
  toasting: ToastServiceMethods
}>()

const visible = defineModel<boolean>('visible')

const emit = defineEmits<{
  (e: 'refreshUsers'): void
}>()

const { roles, loading, organizationEmails } = storeToRefs(useApi())
const { role: userRole, organizations: userOrganizations } = storeToRefs(useAuthStore())
const route = useRoute()

const rolesMap = computed(() => {
  return new Map(roles.value.map((role) => [role.id, role.name]))
})

// TODO: Error messages i18n
const schema = toTypedSchema(
  object({
    active: boolean().when((_, schema) =>
      updating
        ? schema.notRequired()
        : schema.required(() => t('validation.required')).default(false)
    ),
    email: string()
      .email(() => t('validation.isEmail'))
      .max(50, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.required')),
    name: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .transform((value) => capitalizeString(value))
      .required(() => t('validation.required')),
    organizations: array()
      .of(string().uuid())
      .when('roleId', (roleId, schema) => {
        if (userRole.value.name === Roles.admin) {
          switch (rolesMap.value.get(roleId[0])) {
            case Roles.admin:
              return schema.notRequired()
            case Roles.manager:
              return schema
                .min(1, () => t('validation.required'))
                .required(() => t('validation.required'))
                .default([])
            default:
              return schema
                .length(1, () => t('validation.required'))
                .required(() => t('validation.required'))
                .default([])
          }
        } else if (userRole.value.name === Roles.manager && userOrganizations.value.length > 1) {
          return schema
            .length(1)
            .required(() => t('validation.required'))
            .default([])
        } else {
          return schema.notRequired()
        }
      }),
    phone: string()
      .max(50, ({ max }) => t('validation.max', { max }))
      .default(''),
    surname: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .transform((value) => capitalizeString(value))
      .default(''),
    roleId: string()
      .uuid()
      .required(() => t('validation.required'))
  })
)

const { defineField, handleSubmit, resetForm, errors, meta, setFieldValue } = useForm({
  validationSchema: schema
})

const [active] = defineField('active')
const [email] = defineField('email')
const [name] = defineField('name')
const [organizations] = defineField('organizations')
const [phone] = defineField('phone')
const [surname] = defineField('surname')
const [roleId] = defineField('roleId')

const onSubmit = handleSubmit(async (values) => {
  try {
    loading.value = true
    if (updatedUserId) {
      await ApiService.updateEntity(RouteNamespace.users, updatedUserId, {
        ...values,
        organizations:
          rolesMap.value.get(values.roleId) === Roles.admin
            ? []
            : values.organizations
              ? values.organizations
              : [userOrganizations.value[0].id]
      })
    } else {
      await ApiService.createEntity(RouteNamespace.users, {
        ...values,
        organizations:
          rolesMap.value.get(values.roleId) === Roles.admin
            ? []
            : values.organizations
              ? values.organizations
              : [userOrganizations.value[0].id]
      })
    }
    toasting.add({
      group: 'success',
      severity: 'success',
      summary: updating
        ? t('detail.user.notifications.updateSuccess')
        : t('detail.user.notifications.createSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
    toasting.add({
      group: 'error',
      severity: 'error',
      summary: updating
        ? t('detail.user.notifications.updateError')
        : t('detail.user.notifications.createError'),
      life: 3000
    })
  } finally {
    visible.value = false
    emit('refreshUsers')
    loading.value = false
  }
})
const handleCancel = () => {
  visible.value = false
}
const resetParams = () => {
  if (route.query.active) router.replace({ query: { ...route.query, active: undefined } })
}
const filteredRoles = computed(() => {
  const adminFilter = () => true
  const managerFilter = (role) =>
    role['name'] !== Roles.admin &&
    role['name'] !== Roles.manager &&
    role['name'] !== Roles.technician
  const ownerFilter = (role) =>
    role['name'] !== Roles.admin && role['name'] !== Roles.manager && role['name'] !== Roles.owner
  let filter

  switch (userRole.value.name) {
    case Roles.admin:
      filter = adminFilter
      break
    case Roles.manager:
      filter = managerFilter
      break
    case Roles.owner:
      filter = ownerFilter
      break
    default:
      filter = () => false
  }

  return roles.value.filter(filter).map((role) => ({
    label: t(`roles.${role['name']}`),
    value: role['id']
  }))
})
watch(roleId, () => {
  if (rolesMap.value.get(roleId.value) === Roles.admin || organizations.value?.length !== 1) {
    setFieldValue(
      'organizations',
      rolesMap.value.get(roleId.value) === Roles.admin
        ? []
        : updatedUser?.organizations?.map((org) => org.id)
    )
  }
})
watch(visible, () => {
  resetForm({
    values: {
      ...updatedUser,
      organizations: updatedUser?.organizations?.map((org) => org.id),
      roleId: updatedUser?.role?.id
    }
  })
})
</script>

<template>
  {{ '' /* TODO: Error message layout integration */ }}
  <BaseDialog
    v-model:visible="visible"
    :closable="false"
    :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
    @hide="resetParams"
  >
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-4 ml-3">
        <div v-if="updatedUser" class="flex flex-row">
          <p class="p-dialog-title mr-1">{{ t('detail.user.actions.update') }} -</p>
          <p class="p-dialog-title font-family-light font-italic">
            {{ capitalizeString(updatedUser?.name || '') }}
            {{ capitalizeString(updatedUser?.surname || '') }}
          </p>
        </div>
        <p v-else class="p-dialog-title">{{ t('detail.user.actions.create') }}</p>
      </div>
      <div class="absolute top-0 right-0 mt-4 mb-4 mr-3">
        <svg-icon name="user-face" size="24" color="white" />
      </div>
    </template>
    <template #body>
      <div
        v-if="
          userRole.name === Roles.admin ||
          (userRole.name === Roles.manager && userOrganizations.length > 1)
        "
        class="flex flex-row"
      >
        <div class="field col-12">
          <label
            for="organizations"
            :class="
              rolesMap.get(roleId) === Roles.admin
                ? 'font-family-light'
                : 'font-family-light required'
            "
            >{{ t('detail.header.organization') }}</label
          >
          <MultiSelect
            v-model="organizations"
            id="organizations"
            aria-describedby="organizations-help"
            class="h-3rem align-items-center"
            filter
            :disabled="rolesMap.get(roleId) === Roles.admin"
            display="chip"
            optionLabel="email"
            optionValue="id"
            :options="userRole.name === Roles.admin ? organizationEmails : userOrganizations"
            :selectionLimit="
              userRole.name === Roles.admin && rolesMap.get(roleId) === Roles.manager
                ? undefined
                : 1
            "
            :invalid="!!errors.organizations"
            :emptyMessage="t('detail.organization.notFound')"
            :placeholder="
              rolesMap.get(roleId) === Roles.admin
                ? ''
                : t('detail.user.dialog.placeholder.organization')
            "
            :pt="{
              item: ({ context }) => ({
                class: context.selected
                  ? 'bg-gray-300'
                  : context.focused
                    ? 'bg-gray-100'
                    : undefined
              })
            }"
          >
            <template #dropdownicon>
              <div class="flex flex-column justify-content-center p-0 col-12">
                <svg-icon name="arrow-down" size="18" color="#9E9E9E" />
              </div>
            </template>
          </MultiSelect>
          <small id="organizations-help" class="p-error">
            {{ errors.organizations }}
          </small>
        </div>
      </div>
      <div class="flex flex-row justify-content-between p-0 col-12">
        <div class="field col-6">
          <label for="name" class="required">{{ t('detail.user.dialog.name') }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="user-face" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="name"
              id="name"
              type="text"
              aria-describedby="name-help"
              :invalid="!!errors.name"
              :placeholder="t('detail.user.dialog.placeholder.name')"
            />
          </IconField>
          <small id="name-help" class="p-error">
            {{ errors.name }}
          </small>
        </div>
        <div class="field col-6">
          <label for="surname">{{ t('detail.user.dialog.surname') }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="user" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="surname"
              id="surname"
              type="text"
              aria-describedby="surname-help"
              :invalid="!!errors.surname"
              :placeholder="t('detail.user.dialog.placeholder.surname')"
            />
          </IconField>
          <small id="surname-help" class="p-error">
            {{ errors.surname }}
          </small>
        </div>
      </div>
      <div class="flex flex-row justify-content-between">
        <div class="field col-6">
          <label for="phone">{{ t('detail.user.dialog.phone') }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="phone" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="phone"
              id="phone"
              type="text"
              aria-describedby="phone-help"
              :invalid="!!errors.phone"
              :placeholder="t('detail.user.dialog.placeholder.phone')"
            />
          </IconField>
          <small id="phone-help" class="p-error">
            {{ errors.phone }}
          </small>
        </div>
        <div class="field col-6">
          <label for="email" class="required">{{ t('detail.user.dialog.email') }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="mail" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="email"
              id="email"
              type="text"
              aria-describedby="email-help"
              :invalid="!!errors.email"
              :placeholder="t('detail.user.dialog.placeholder.email')"
            />
          </IconField>
          <small id="email-help" class="p-error">
            {{ errors.email }}
          </small>
        </div>
      </div>
      <div class="flex flex-row align-items-center justify-content-between">
        <div class="field col-6">
          <label for="role" class="required">{{ t('detail.user.header.role') }}</label>
          <Select
            v-model="roleId"
            id="roleId"
            class="w-12 h-3rem align-items-center"
            aria-describedby="roleId-help"
            :options="filteredRoles"
            optionLabel="label"
            optionValue="value"
            :emptyMessage="t('detail.user.dialog.placeholder.notFoundRoles')"
            :invalid="!!errors.roleId"
            :placeholder="t('detail.user.dialog.placeholder.role')"
            :pt="{
              item: ({ context }) => ({
                class: context.selected
                  ? 'bg-gray-300'
                  : context.focused
                    ? 'bg-gray-100'
                    : undefined
              })
            }"
          >
            <template #dropdownicon>
              <div class="flex flex-column justify-content-center p-0 col-12">
                <svg-icon name="arrow-down" size="18" color="#E9E9E9" />
              </div>
            </template>
          </Select>
          <small id="roleId-help" class="p-error">
            {{ errors.roleId }}
          </small>
        </div>
        <div v-if="!updating" class="flex flex-row col-5">
          <Checkbox v-model="active" id="active" :binary="true" />
          <label for="active" class="ml-2"> {{ t('detail.user.header.activation') }} </label>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
        <FooterDialog @cancel="handleCancel" @confirm="onSubmit" remove :disabled="!meta.valid" />
      </div>
    </template>
  </BaseDialog>
</template>
