<script setup lang="ts">
import { t } from '@/common/i18n'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import { computed, ref } from 'vue'
import { type RouteLocationNormalizedLoaded, useRoute } from 'vue-router'
import { ApiService } from '@/services/ApiService'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import router from '@/router'

const passwordInitial = ref<string>('')
const passwordInitialRepeated = ref<string>('')
const route = useRoute() as RouteLocationNormalizedLoaded & {
  params: {
    id: string
  }
}
const passwordsMatch = computed(() => {
  return passwordInitial.value === passwordInitialRepeated.value && passwordInitial.value !== ''
})
const resetPassword = async (password: string) => {
  const id = route.params.id
  try {
    await ApiService.resetPassword(`${RouteNamespace.resetPassword}/${id}`, password)
  } catch (error) {
    console.error(error)
  } finally {
    await router.push({ name: RouteNamespace.login })
  }
}
</script>

<template>
  <div class="flex flex-row min-h-screen align-content-center min-w-screen overflow-hidden">
    <section class="flex p-0 w-3 reset-container" />
    <section class="flex">
      <div
        class="flex flex-column justify-content-center align-items-center w-full py-8 px-5 sm:px-8"
      >
        <div class="flex flex-column align-items-center">
          <svg-icon name="chargevite" size="110" color="#EA2839" />
          <span class="text-4xl mb-1">{{ t('detail.auth.resetPassword') }}</span>
        </div>
        <form class="field" @submit.prevent>
          <div class="flex flex-column col-12">
            <label class="font-family-light text-700 text-2xl line-height-3">{{
              t('auth.password')
            }}</label>
            <IconField icon-position="left">
              <InputIcon style="z-index: 1">
                <svg-icon name="lock" size="18" color="#9E9E9E" />
              </InputIcon>
              <Password
                v-model="passwordInitial"
                type="password"
                :placeholder="t('auth.placeholder.password')"
                toggleMask
                :inputStyle="{
                  paddingLeft: 'calc((var(--p-form-field-padding-x) * 2) + var(--p-icon-size))',
                  paddingRight: 'calc((var(--p-form-field-padding-x) * 2) + var(--p-icon-size))'
                }"
                inputClass="w-full md:w-30rem mt-1"
                :weakLabel="t('auth.severity.weak')"
                mediumRegex="^.{9,14}$"
                :mediumLabel="t('auth.severity.medium')"
                strongRegex="^.{15,}$"
                :strongLabel="t('auth.severity.strong')"
                :promptLabel="t('auth.severity.prompt')"
                :input-props="{ autocomplete: 'on' }"
                :pt="{
                  meter: {
                    class: 'bg-gray-500'
                  }
                }"
              />
            </IconField>
          </div>
          <div class="flex flex-column col-12">
            <label class="font-family-light text-700 text-2xl line-height-3">{{
              t('auth.placeholder.passwordRepeated')
            }}</label>
            <IconField icon-position="left">
              <InputIcon style="z-index: 1">
                <svg-icon name="lock" size="18" color="#9E9E9E" />
              </InputIcon>
              <Password
                v-model="passwordInitialRepeated"
                type="password"
                :placeholder="t('auth.placeholder.password')"
                toggleMask
                :inputStyle="{
                  paddingLeft: 'calc((var(--p-form-field-padding-x) * 2) + var(--p-icon-size))',
                  paddingRight: 'calc((var(--p-form-field-padding-x) * 2) + var(--p-icon-size))'
                }"
                inputClass="w-full md:w-30rem mt-1"
                :feedback="false"
                :input-props="{ autocomplete: 'on' }"
                :pt="{
                  meter: {
                    class: 'bg-gray-500'
                  }
                }"
              />
            </IconField>
          </div>
          <div class="flex flex-column justify-content-around align-items-center mt-4 h-6rem">
            <Button
              :disabled="!passwordsMatch"
              :type="'submit'"
              :label="t('detail.auth.resetPassword')"
              class="p-3 text-l button button-cancel"
              icon="pi pi-arrow-right"
              rounded
              iconPos="right"
              @click="resetPassword(passwordInitial)"
            />
          </div>
        </form>
      </div>
    </section>
    <BasePushNotification
      class="absolute right-0"
      group="resetPassword"
      :summary="t('auth.reset')"
    />
  </div>
</template>

<style scoped lang="scss">
section {
  flex: 1;
}

.password-icon {
  top: 1.2rem;
  left: 0.5rem;
}

:deep(.password-field input[type='password']) {
  padding-left: 3rem;
}

.reset-container {
  background-image: url('@/assets/background-scaled.jpg');
  background-size: cover;
}
</style>
