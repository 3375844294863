import type { App, Component } from 'vue'

const svgIcons: string[] = []

export default {
  /* SvgIconLoader
   *
   * This plugin load all svgs from folder and create a "svg-icon-<filename>" component dynamically
   */
  install(app: App) {
    const files = import.meta.glob('@/assets/icons/svg/*.svg', { eager: true })
    for (const key of Object.keys(files)) {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched) {
        const componentName = 'svg-icon-' + matched[1]
        const component = files[key] as unknown as Component

        app.component(componentName, component)
        svgIcons.push(matched[1])
      }
    }
  }
}

export { svgIcons }
