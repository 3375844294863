<script setup lang="ts">
import { t } from '@/common/i18n'
import { useConfirm } from 'primevue/useconfirm'
import type { AcceptFunction, CancelFunction } from '@/models/ui/BaseConfirmPopUp'

const confirm = useConfirm()
const showConfirmPopup = (event: Event, accept: AcceptFunction, cancel: CancelFunction) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    // message: t('actions.confirmRemove'),
    group: 'removeAction',
    acceptLabel: t('yes'),
    rejectLabel: t('no'),
    acceptClass: 'p-button-rounded button button-save',
    accept: accept,
    reject: cancel,
    rejectClass: 'p-button-rounded button button-cancel'
  })
}
defineExpose({
  showConfirmPopup
})
</script>

<template>
  <ConfirmPopup group="removeAction">
    <template #message>
      <div class="flex flex-column p-3 max-w-4">
        <i
          class="pi pi-info-circle flex mb-4 justify-content-center text-red-500"
          style="font-size: 2rem"
        ></i>
        <p class="font-bold">
          {{ t('actions.confirmRemove') }}
        </p>
        <p>
          {{ t('actions.confirmRemoveSubtitle') }}
        </p>
      </div>
    </template>
  </ConfirmPopup>
</template>
