<script setup lang="ts">
import { onBeforeMount, ref, watch } from 'vue'
import type { ItemsWarning, WarningSchema } from '@/models/ui/Alerts'

const IconSetting = {
  info: `pi-info-circle icon-color-blue`,
  danger: `pi-times-circle icon-color-red`,
  warning: `pi-exclamation-triangle icon-color-orange`
}
//TODO remove hardcoded data ASAP, maybe it is valuable handle this topic via pinia
const alarm = ref<Array<WarningSchema>>([
  { level: 'danger', description: `Cargador "test" deshabilitado`, sentMail: false },
  { level: 'info', description: `Todos los cargadores disponibles`, sentMail: false }
])
setTimeout(() => {
  alarm.value.push({
    level: 'warning',
    description: `Incidencia con cargador Test`,
    sentMail: false
  })
}, 5000)
setTimeout(() => {
  alarm.value.push({
    level: 'danger',
    description: `Cargador "Cogullada" deshabilitado`,
    sentMail: false
  })
}, 10000)
const itemsWarning = ref<Array<ItemsWarning>>([])
const counter = ref(0)

const mountMenuWarnings = (warnings: Array<WarningSchema> | undefined) => {
  if (warnings !== undefined) {
    warnings.forEach(async (warning) => {
      itemsWarning.value.push({
        label: warning.description,
        icon: `pi pi-fw ${IconSetting[warning.level]}`
      })
      if (warning.level === 'danger' && !warning.sentMail) {
        //await ApiService.sendMail(RouteNamespace.email, { payload }) //TODO feature needs to be clarified
        warning.sentMail = true
      }
    })
    counter.value = warnings.length
  }
}

onBeforeMount(() => {
  mountMenuWarnings(alarm.value)
})
watch([alarm.value], () => {
  itemsWarning.value = []
  mountMenuWarnings(alarm.value)
})
</script>

<template>
  <div class="flex flex-column sticky bg-white shadow-1 p-4 z-1 mb-4 border-round-lg">
    <slot name="header"></slot>
    <slot name="body"></slot>
    <slot name="footer"></slot>
  </div>
</template>

<style lang="scss">
.active #settings {
  background-color: var(--darkGreen) !important;
  color: white !important;
}

.icon-color-orange {
  color: var(--yellow) !important;
}

.icon-color-red {
  color: var(--red) !important;
}

.icon-color-blue {
  color: var(--green) !important;
}
</style>
