import type { Location } from '@/models'

export const findLocation = (cpId: string, data: Location[]) => {
  for (let i = 0; i < data.length; i++) {
    const result = data[i].stations.filter((station) => station.cpId === cpId)
    if (result.length > 0) {
      return data[i].id
    }
  }
  return ''
}
