<script setup lang="ts">
import { Vue3Lottie } from 'vue3-lottie'

interface Lottie {
  icon: unknown
  label: string
}

const props = defineProps<Lottie>()
</script>

<template>
  <div class="container">
    <div class="flex flex-column align-items-center">
      <Vue3Lottie :animationData="props.icon" height="20%" width="20%" :loop="1" />
      <p>{{ props.label }}</p>
    </div>
  </div>
</template>
