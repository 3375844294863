export interface Role {
  id?: string
  name: Roles
}

export enum Roles {
  admin = 'admin',
  manager = 'manager',
  owner = 'owner',
  technician = 'technician',
  viewer = 'viewer'
}
