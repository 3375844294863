<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useForm } from 'vee-validate'
import { object, string } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import router from '@/router'
import { t } from '@/common/i18n'
import { capitalizeString } from '@/utils/capitalize'
import { useApi } from '@/stores/api/api'
import { ApiService } from '@/services/ApiService'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import FooterDialog from '@/components/partials/FooterDialog.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import type { ToastServiceMethods } from 'primevue/toastservice'
import type { FileUploadEvent } from '@/models/ui/FileUpload'
import { type Organization } from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace'

const { updatedOrganization, updatedOrganizationId, updating, toasting } = defineProps<{
  updatedOrganization?: Partial<Organization>
  updatedOrganizationId?: string
  updating?: boolean
  toasting: ToastServiceMethods
}>()
const avatarIcon = ref<string>('')
const newProfileImgFile = ref<File | null>(null)
const visible = defineModel<boolean>('visible')

const emit = defineEmits<{
  (e: 'refreshOrganizations'): void
}>()

const { loading } = storeToRefs(useApi())
const { fetchOrganizationEmails } = useApi()
const route = useRoute()

const schema = toTypedSchema(
  object({
    address: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .default(''),
    city: string()
      .max(40, ({ max }) => t('validation.max', { max }))
      .transform((value) => capitalizeString(value))
      .default(''),
    country: string()
      .max(25, ({ max }) => t('validation.max', { max }))
      .transform((value) => capitalizeString(value))
      .default(''),
    description: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .default(''),
    email: string()
      .email(() => t('validation.isEmail'))
      .max(100, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.required')),
    identityNumber: string()
      .max(25, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.required')),
    imageProfile: string()
      .max(150, ({ max }) => t('validation.max', { max }))
      .default(''),
    name: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .transform((value) => capitalizeString(value))
      .required(() => t('validation.required')),
    phone: string()
      .max(25, ({ max }) => t('validation.max', { max }))
      .default(''),
    postcode: string()
      .max(25, ({ max }) => t('validation.max', { max }))
      .default(''),
    province: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .transform((value) => capitalizeString(value))
      .default('')
  })
)

const { defineField, handleSubmit, resetForm, errors, meta } = useForm({
  validationSchema: schema
})

const [address] = defineField('address')
const [city] = defineField('city')
const [country] = defineField('country')
const [email] = defineField('email')
const [identityNumber] = defineField('identityNumber')
const [imageProfile] = defineField('imageProfile')
const [name] = defineField('name')
const [phone] = defineField('phone')
const [postcode] = defineField('postcode')
const [province] = defineField('province')

const onSubmit = handleSubmit(async (values) => {
  try {
    loading.value = true
    if (updatedOrganizationId) {
      await ApiService.updateEntity<Organization>(
        RouteNamespace.organizations,
        updatedOrganizationId,
        values
      )
    } else {
      await ApiService.createEntity<Organization>(RouteNamespace.organizations, values)
    }
    await fetchOrganizationEmails()
    toasting.add({
      group: 'success',
      severity: 'success',
      summary: updating
        ? t('detail.organization.notifications.updateSuccess')
        : t('detail.organization.notifications.createSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
    toasting.add({
      group: 'error',
      severity: 'error',
      summary: updating
        ? t('detail.organization.notifications.updateError')
        : t('detail.organization.notifications.createError'),
      life: 3000
    })
  } finally {
    visible.value = false
    emit('refreshOrganizations')
    loading.value = false
  }
})
const handleCancel = () => {
  visible.value = false
}
const handleUpload = (event: FileUploadEvent) => {
  const file = event.files && event.files[event.files.length - 1]
  if (file) {
    const reader = new FileReader()
    reader.onload = () => {
      newProfileImgFile.value = file
      imageProfile.value = URL.createObjectURL(file)
    }
    reader.readAsDataURL(file)
    avatarIcon.value = file.name
  }
  event.files.pop()
}
const resetParams = () => {
  if (route.query.active) router.replace({ query: { ...route.query, active: undefined } })
}
watch(visible, () => {
  resetForm({ values: updatedOrganization })
})
</script>

<template>
  {{ '' /* TODO: Error message layout integration */ }}
  <BaseDialog
    v-model:visible="visible"
    :closable="false"
    :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
    @hide="resetParams"
  >
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-4 ml-3">
        <div v-if="updatedOrganization" class="flex flex-row">
          <p class="p-dialog-title mr-1">{{ t('detail.organization.actions.update') }} -</p>
          <p class="p-dialog-title font-family-light font-italic">
            {{ capitalizeString(updatedOrganization['name'] || '') }}
          </p>
        </div>
        <p v-else class="p-dialog-title">{{ t('detail.organization.actions.create') }}</p>
      </div>
    </template>
    <template #header>
      <div class="absolute top-0 right-0 mt-4 mr-3">
        <svg-icon name="organization" size="24" color="white" />
      </div>
    </template>
    <template #body>
      <div class="flex flex-row justify-content-between p-0 col-12">
        <div class="field col-6">
          <label class="font-family-light required" for="name">{{
            t('detail.organization.header.name')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="organization" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="name"
              id="name"
              type="text"
              aria-describedby="name-help"
              :invalid="!!errors.name"
              :placeholder="t('detail.organization.dialog.placeholder.name')"
            />
          </IconField>
          <small id="name-help" class="p-error">
            {{ errors.name }}
          </small>
        </div>
        <div class="field col-5">
          <label class="font-family-light required" for="identityNumber">{{
            t('detail.organization.header.identityNumber')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="nif" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="identityNumber"
              id="identityNumber"
              type="text"
              aria-describedby="identityNumber-help"
              :invalid="!!errors.identityNumber"
            />
          </IconField>
          <small id="identityNumber-help" class="p-error">
            {{ errors.identityNumber }}
          </small>
        </div>
      </div>
      <div class="flex flex-row p-0 col-12">
        <div class="field col-12">
          <label class="font-family-light" for="address">{{
            t('detail.organization.header.address')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="address" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="address"
              id="address"
              aria-describedby="address-help"
              type="text"
              :invalid="!!errors.address"
            />
          </IconField>
          <small id="address-help" class="p-error">
            {{ errors.address }}
          </small>
        </div>
      </div>
      <div class="flex flex-row justify-content-between p-0 col-12">
        <div class="field col-6">
          <label class="font-family-light" for="city">{{
            t('detail.organization.header.city')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="city" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="city"
              id="city"
              aria-describedby="city-help"
              type="text"
              :invalid="!!errors.city"
              :placeholder="t('detail.organization.dialog.placeholder.city')"
            />
          </IconField>
          <small id="city-help" class="p-error">
            {{ errors.city }}
          </small>
        </div>
        <div class="field col-6">
          <label class="font-family-light" for="province">{{
            t('detail.organization.header.province')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="province" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="province"
              id="province"
              aria-describedby="province-help"
              type="text"
              :invalid="!!errors.province"
              :placeholder="t('detail.organization.dialog.placeholder.province')"
            />
          </IconField>
          <small id="province-help" class="p-error">
            {{ errors.province }}
          </small>
        </div>
      </div>
      <div class="flex flex-row justify-content-between p-0 col-12">
        <div class="field col-6">
          <label class="font-family-light" for="postcode">{{
            t('detail.organization.header.postcode')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="postcode" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="postcode"
              id="postcode"
              aria-describedby="postcode-help"
              type="text"
              :invalid="!!errors.postcode"
              :placeholder="t('detail.organization.dialog.placeholder.postcode')"
            />
          </IconField>
          <small id="postcode-help" class="p-error">
            {{ errors.postcode }}
          </small>
        </div>
        <div class="field col-6">
          <label class="font-family-light" for="country">{{
            t('detail.organization.header.country')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="country" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="country"
              id="country"
              aria-describedby="country-help"
              type="text"
              :invalid="!!errors.country"
              :placeholder="t('detail.organization.dialog.placeholder.country')"
            />
          </IconField>
          <small id="country-help" class="p-error">
            {{ errors.country }}
          </small>
        </div>
      </div>
      <div class="flex flex-row justify-content-between p-0 col-12">
        <div class="field col-6">
          <label class="font-family-light required" for="email">{{
            t('detail.organization.header.email')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="mail" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="email"
              id="email"
              aria-describedby="email-help"
              type="text"
              :invalid="!!errors.email"
              :placeholder="t('detail.organization.dialog.placeholder.email')"
            />
          </IconField>
          <small id="email-help" class="p-error">
            {{ errors.email }}
          </small>
        </div>
        <div class="field col-6">
          <label class="font-family-light" for="phone">{{
            t('detail.organization.header.phone')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="phone" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="phone"
              id="phone"
              aria-describedby="phone-help"
              type="text"
              :invalid="!!errors.phone"
              :placeholder="t('detail.organization.dialog.placeholder.phone')"
            />
          </IconField>
          <small id="phone-help" class="p-error">
            {{ errors.phone }}
          </small>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="flex flex-column field col-6">
          <label class="font-family-light" for="upload">{{
            t('detail.organization.actions.upload')
          }}</label>
          <div class="flex flex-row justify-content-between mt-1">
            <Avatar class="border-1 border-100 w-5rem h-5rem" shape="circle" :image="imageProfile">
              <template #default>
                <svg-icon v-if="!imageProfile" name="user" size="48" color="#9E9E9E" />
              </template>
            </Avatar>
            <FileUpload
              class="p-button-rounded w-fit"
              mode="basic"
              name="demo[]"
              url="/api/upload"
              accept="image/*"
              :maxFileSize="1000000"
              :chooseLabel="t('detail.organization.actions.upload')"
              @select="handleUpload"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
        <FooterDialog @cancel="handleCancel" @confirm="onSubmit" remove :disabled="!meta.valid" />
      </div>
    </template>
  </BaseDialog>
</template>
