<script setup lang="ts">
import SvgIcon from '@/common/icons/SvgIcon.vue'
import type { CardDashboard } from '@/models/ui/Dashboard'
import { t } from '@/common/i18n'

const {
  header = '',
  headerSubtitle = '',
  contentDescription = '',
  icon = '',
  iconColor = '',
  iconClass = '',
  visible = true,
  value = 0,
  total = 0
} = defineProps<CardDashboard>()

const UNIT = '%'
</script>

<template>
  <Card class="dashboard-card-primary p-1 shadow-1 mb-2">
    <template #title>
      <div class="flex flex-column">
        <div class="flex flex-row justify-content-between align-items-center h-4rem w-full">
          <div class="flex flex-column justify-content-between max-h-3rem">
            <span class="font-family font-bold text-lg">{{ header }}</span>
            <span class="font-family-light text-base mt-2 h-fit">{{ headerSubtitle }}</span>
          </div>
          <div class="circle-icon" :class="iconClass">
            <svg-icon :name="icon" size="18" :color="iconColor" />
          </div>
        </div>
      </div>
    </template>
    <template #subtitle>
      <div class="flex flex-row align-items-baseline mt-4 h-3rem">
        <span class="font-bold text-3xl mr-2">{{
          total !== 0 ? ((value / total) * 100).toFixed(0) : 0
        }}</span>
        <span class="font-bold text-2xl">{{ UNIT }}</span>
      </div>
    </template>
    <template #content>
      <div class="flex flex-row justify-content-between">
        <div>
          <span class="font-family-light font-bold">{{ value }}</span>
          <span id="footer" class="font-family-light font-bold ml-1">{{ content }}</span>
        </div>
        <div>
          <small class="text-500">{{ total }}</small>
          <small id="footer" class="ml-1 text-500">{{ contentDescription }}</small>
        </div>
      </div>
      <div class="mt-4">
        <ProgressBar :value="total !== 0 ? +((value / total) * 100).toFixed(0) : 0" />
      </div>
      <div class="flex flex-row justify-content-between align-content-center mt-2" v-if="visible">
        <div class="flex flex-row align-items-center">
          <span class="status status__unavailable" />
          <span id="footer" class="font-family font-bold ml-1">{{ t('status.disconnected') }}</span>
        </div>
        <div>
          <span class="">{{ value }}</span>
        </div>
      </div>
    </template>
  </Card>
</template>

<style scoped lang="scss">
.baseCardDashboard {
  border-radius: 12px;
  min-width: 365px;
  height: 280px;
}

.circle-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 24px;
}

.circle-icon__default {
  background-color: var(--status-default-decorator);
}

.circle-icon__available {
  background-color: var(--status-available-decorator);
}

.circle-icon__unavailable {
  background-color: var(--status-unavailable-decorator);
}

.p-progressbar {
  background-color: var(--lightGrayDetail);
  height: 25px;
}

.p-progressbar .p-progressbar-value {
  background-color: var(--status-available-decorator);
}
</style>
