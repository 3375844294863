export interface Cluster {
  getChildCount(): number
}

export type State = 'available' | 'unavailable' | 'suspended' | 'disconnected'
export const STATES: Record<State, State> = {
  available: 'available',
  unavailable: 'unavailable',
  suspended: 'suspended',
  disconnected: 'disconnected'
}

export const COLORS: Record<State, string> = {
  available: '#00DB7F',
  unavailable: '#FB1626',
  suspended: '#FFC107',
  disconnected: '#626868'
}

export const attribution =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
