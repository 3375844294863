import type { TotalRecords } from '@/models/common/domain/pagination'

export interface Transaction {
  id: string
  duration: string
  initialMeasure: string
  finalMeasure: string
  energy: number
  connector: Connection
  station: Station
  cost: number
  causeEnding: string
  currency: string
  reimbursable: number
  type: number
  soc: number
  ticket: string
  invoice: string
  paid: boolean
  order: string
  createdAt: string
  updateAt: string
}

export enum TypeDocument {
  TICKETS = 'tickets',
  INVOICES = 'invoices'
}

export interface TransactionResponse extends TotalRecords {
  transactions: Transaction[]
}
interface Connection {
  connection: number
}

interface Station {
  alias: string
}
