<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import type { RouteItem } from '@/models/ui/Menu'

const authStore = useAuthStore()
const route = useRoute()
const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  index: {
    type: Number,
    default: 0
  },
  root: {
    type: Boolean,
    default: true
  },
  visible: {
    type: Boolean,
    default: false
  }
})

const checkActiveRoute = (item: RouteItem) => {
  return (
    route.path.startsWith(item.to ? item.to : '') ||
    (item.children && item.children.some(checkActiveRoute))
  )
}

const regularItems = computed(() => {
  return props.item.items || []
})
</script>
<template>
  <li
    :class="{
      'layout-root-menuitem': root,
      'preference-item': item.footerItem
    }"
  >
    <template v-if="root && !item['footerItem']">
      <div v-show="visible" class="flex align-items-center layout-menuitem-root-text">
        <svg-icon :name="item['icon']" size="24" />
        <span class="ml-2">{{ item['label'] }}</span>
      </div>
    </template>
    <template v-if="item['to'] && item['footerItem']">
      <router-link class="flex align-items-center layout-menuitem-root-text" :to="item['to']">
        <svg-icon :name="item['icon']" size="20" />
        <span class="ml-2">{{ item['label'] }}</span>
      </router-link>
    </template>
    <router-link
      v-if="item['to'] && !item['footerItem']"
      :class="[item['class'], { 'active-route': checkActiveRoute(item) }]"
      tabindex="0"
      :to="item['to']"
    >
      <svg-icon
        :name="item['icon']"
        size="20"
        :color="checkActiveRoute(item) ? 'white' : '#9E9E9E'"
      />
      <span class="layout-menuitem-text ml-2">{{ item['label'] }}</span>
    </router-link>
    <ul v-if="item['items']" class="flex flex-column pt-1 layout-submenu">
      <template v-for="(child, i) in regularItems">
        <app-menu-item
          v-if="child.roles.includes(authStore.role.name)"
          :id="child.icon"
          :key="child"
          :index="i"
          :item="child"
          :root="false"
        />
      </template>
    </ul>
  </li>
</template>
