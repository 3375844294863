<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { i18n, t } from '@/common/i18n'
import { FilterMatchMode } from '@primevue/core/api'
import type { PageState } from 'primevue/paginator'
import { useToast } from 'primevue/usetoast'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { useApi } from '@/stores/api/api'
import { storeToRefs } from 'pinia'
import { ApiService } from '@/services/ApiService'
import { capitalizeString } from '@/utils/capitalize'
import clock from '@/assets/lottie/clock.json'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import BaseConfirmDeletePopup from '@/components/ui/BaseConfirmDeletePopup.vue'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import router from '@/router'
import AppTopbar from '@/layout/AppTopbar.vue'
import CustomerCreationDialog from '@/components/organizations/OrganizationCreationDialog.vue'
import type {
  Organization,
  OrganizationResponse
} from '@/models/domain/organization/api/Organization'
import { UnitOfMeasure } from '@/models/ocpp/enums'

const { loading } = storeToRefs(useApi())
const popup = ref()
const updatedOrganization = ref<Partial<Organization>>()
const updatedOrganizationId = ref<string>('')
const activeCreationDialog = ref<boolean>(false)
const activeUpdateDialog = ref<boolean>(false)
const filters = ref({
  global: { value: undefined, matchMode: FilterMatchMode.CONTAINS }
})
const organizations = ref<Organization[]>([])
const totalRecords = ref<number>(0)
const rowsPerPage = ref<number>(10)
const currentPage = ref<number>(0)
const toast = useToast()

const getOrganizations = async () => {
  try {
    const response = await ApiService.readAllEntities<OrganizationResponse>(
      `${RouteNamespace.organizations}?limit=${rowsPerPage.value}&offset=${currentPage.value}`
    )
    organizations.value = response['organizations']
    totalRecords.value = response['totalRecords']
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  }
}
const handleCreate = () => {
  activeCreationDialog.value = true
}
const handleUpdate = (row: Partial<Organization>) => {
  updatedOrganization.value = {
    id: row['id'],
    address: row.address,
    city: row.city,
    country: row.country,
    email: row.email,
    identityNumber: row.identityNumber,
    imageProfile: row.imageProfile,
    name: row.name,
    phone: row.phone,
    postcode: row.postcode,
    province: row.province
  }
  activeUpdateDialog.value = true
}
const actionsRemoveOrganization = async (organization: string) => {
  try {
    const payload = {
      isDeleted: true
    }
    await ApiService.updateEntity(RouteNamespace.organizations, organization['id'], payload)
    toast.add({
      group: 'success',
      severity: 'success',
      summary: i18n.global.t('detail.organization.notifications.deleteSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    await getOrganizations()
  }
}
const handleRemove = async (event: Event, row: string) => {
  popup.value.showConfirmPopup(
    event,
    async () => {
      loading.value = true
      await actionsRemoveOrganization(row)
      loading.value = false
    },
    undefined
  )
}
const onPageChange = (event: PageState) => {
  currentPage.value = event.page
  getOrganizations()
}
onMounted(async () => {
  try {
    loading.value = true
    await getOrganizations()
    //open modal dialog from query param
    if (router.currentRoute.value.query.active) {
      activeCreationDialog.value = true
    }
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    loading.value = false
  }
})
</script>
<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl">
        <div class="flex flex-row ml-2 justify-content-between align-items-center">
          <div class="flex">
            <svg-icon name="organization" size="24" color="#626868" />
            <span class="font-bold ml-2 text-2xl">{{ t('dashboard.organizations') }}</span>
          </div>
          <Button
            v-tooltip.top="t('detail.organization.actions.create')"
            class="button button-normal ml-3"
            rounded
            @click="handleCreate"
          >
            <template #icon>
              <svg-icon name="add" size="18" color="#626868" />
            </template>
          </Button>
        </div>
      </div>
    </template>
    <template #body>
      <div class="flex w-3">
        <IconField icon-position="left">
          <InputIcon class="pi pi-search" />
          <InputText
            v-model="filters['global'].value"
            :placeholder="t('search')"
            type="text"
            :pt="{
              root: {
                class: ['border-300']
              }
            }"
          />
        </IconField>
      </div>
    </template>
  </AppTopbar>
  <div class="card h-fit bg-white shadow-none">
    <DataTable
      v-model:filters="filters"
      :value="organizations"
      :globalFilterFields="['name', 'city', 'email', 'country']"
      :rows="rowsPerPage"
      dataKey="id"
      scrollable
    >
      <template #empty>
        <BaseLottieAnimation :icon="clock" :label="t('detail.organization.notFound')" />
      </template>
      <Column field="name" :header="t('detail.organization.header.name')" class="table__name">
        <template #body="slotProps">
          {{ capitalizeString(slotProps.data['name']) }}
        </template>
      </Column>
      <Column
        field="billing"
        :header="t('detail.organization.header.billing')"
        header-class="font-bold"
        class="table__billing"
      >
        <template #body="slotProps">
          {{ `${slotProps.data['totalTransactions']?.toFixed(2)} €` }}
        </template>
      </Column>
      <Column
        field="energy"
        :header="t('detail.organization.header.energy_supplied')"
        header-class="font-bold"
        class="table__energy"
      >
        <template #body="slotProps">
          {{ `${(slotProps.data['totalEnergy'] / 1000).toFixed(2)} ${UnitOfMeasure.KWH}` }}
        </template>
      </Column>
      <Column
        field="users"
        :header="t('detail.organization.header.users')"
        header-class="font-bold"
        class="table__users"
      >
        <template #body="slotProps">
          <Tag :value="slotProps.data['totalUsers']" rounded />
        </template>
      </Column>
      <Column
        field="cards"
        :header="t('detail.organization.header.rfidCards')"
        header-class="font-bold"
        class="table__cards"
      >
        <template #body="slotProps">
          <Tag :value="slotProps.data['totalCards']" rounded />
        </template>
      </Column>
      <Column
        :header="t('detail.header.actions')"
        header-class="table__header font-bold"
        class="table__actions"
      >
        <template #body="slotProps">
          <div class="flex flex-row justify-content-center">
            <Button
              v-tooltip.top="t('detail.organization.actions.update')"
              rounded
              class="button button-normal mr-2"
              @click="handleUpdate(slotProps.data)"
            >
              <template #icon>
                <svg-icon name="edit" size="16" color="#626868" />
              </template>
            </Button>
            <BaseConfirmDeletePopup ref="popup" />
            <Button
              v-tooltip.top="t('detail.organization.actions.delete')"
              icon="pi pi-trash"
              rounded
              class="button button-remove"
              @click="handleRemove($event, slotProps.data)"
            >
              <template #icon>
                <svg-icon name="trash" size="16" />
              </template>
            </Button>
          </div>
        </template>
      </Column>
      <template #footer>
        <Paginator
          @page="onPageChange"
          class="flex justify-content-center"
          :rows="rowsPerPage"
          :totalRecords
          :pt="{
            pageButton: ({ context }) => ({
              class: context.active ? 'bg-gray-500 text-white' : undefined
            })
          }"
        />
      </template>
    </DataTable>
  </div>
  <CustomerCreationDialog
    v-model:visible="activeUpdateDialog"
    updating
    :updated-organization="updatedOrganization"
    :updated-organization-id="updatedOrganizationId"
    :toasting="toast"
    @refresh-organizations="getOrganizations()"
  />
  <CustomerCreationDialog
    v-model:visible="activeCreationDialog"
    :toasting="toast"
    @refresh-organizations="getOrganizations()"
  />
  <BasePushNotification group="success" icon-name="success" color="#00DB7F" />
  <BasePushNotification group="error" icon-name="error" color="#EA2839" />
</template>

<style scoped>
::v-deep(.table__header) > div > span {
  margin: 0 auto;
}

::v-deep(.table__name) {
  width: 16%;
}

::v-deep(.table__role) {
  width: 14%;
}

::v-deep(.table__billing) {
  width: 14%;
}

::v-deep(.table__energy) {
  width: 16%;
}

::v-deep(.table__users) {
  width: 11%;
}

::v-deep(.table__cards) {
  width: 13%;
}

::v-deep(.table__actions) {
  width: 16%;
}
</style>
