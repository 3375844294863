import { ref } from 'vue'

export const hoursRanges = ref([
  {
    code: 'P1',
    name: 'Punta',
    category: '08:00',
    quantity: '20:00',
    quantity2: 'L-V'
  },
  {
    code: 'P2',
    name: 'Valle',
    category: '20:00',
    quantity: '08:00',
    quantity2: 'L-V'
  },
  {
    code: 'P3',
    name: 'Supervalle',
    category: '00:00',
    quantity: '24:00',
    quantity2: 'S - D, Festivos'
  }
])
