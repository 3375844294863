import { defineStore } from 'pinia'
import { socket } from '@/config/socket'
import type { MeterValuesBroadcast } from '@/models/ocpp/Broadcast'
import type {
  LastMeterValue,
  MeterValue,
  MeterValuesRequest
} from '@/models/ocpp/MeterValuesRequest'
import { TopicNameSpace } from '@/models/common/TopicNameSpace'
import { Measurand, UnitOfMeasure } from '@/models/ocpp/enums'

export const useMeterValues = defineStore('meterValues', {
  state: () => ({
    meterValues: {} as { [key: string]: MeterValuesRequest & { cpId: string } }
  }),
  getters: {
    getMeterValues: (state) => state.meterValues,
    getMeterValuesByKey: (state) => (key: string) => {
      return state.meterValues[key]
    },

    getMeterValuesByCpId: (state) => (cpId: string, numberOfConnectors: number) => {
      return Array.from({ length: numberOfConnectors }, (_, i) => {
        const key = `${cpId}:${i + 1}`
        return state.meterValues[key]
      })
    },

    getSuppliedEnergy: (state) => (cpId: string, connectorId: number) => {
      const meterValues = state.meterValues[`${cpId}:${connectorId}`]
      if (!meterValues) return 0
      const { meterValue } = meterValues
      const lastMeterValue: LastMeterValue = {
        activeEnergy: 0,
        timestamp: 0,
        unit: UnitOfMeasure.WH,
        factor: 0.001
      }
      meterValue.forEach((m: MeterValue) => {
        m.sampledValue.forEach((sv) => {
          if (sv.measurand && sv.measurand !== Measurand.ENERGY_ACTIVE_IMPORT_REGISTER) return
          let timestamp: number | string = m.timestamp
          if (typeof timestamp === 'string') timestamp = new Date(m.timestamp).getTime()
          if (lastMeterValue.timestamp === 0 || timestamp > lastMeterValue.timestamp) {
            lastMeterValue.activeEnergy = Number(sv.value)
            lastMeterValue.timestamp = timestamp
            lastMeterValue.unit = sv.unit ? UnitOfMeasure[sv.unit.toUpperCase()] : UnitOfMeasure.WH
            lastMeterValue.factor = lastMeterValue.unit === UnitOfMeasure.WH ? 0.001 : 1
          }
        })
      })
      if (!lastMeterValue) return null
      if (lastMeterValue.timestamp === 0) return null
      if (lastMeterValue.unit !== UnitOfMeasure.WH && lastMeterValue.unit !== UnitOfMeasure.KWH)
        return null
      if (isNaN(lastMeterValue.activeEnergy) || lastMeterValue.activeEnergy < 0) return null
      //round the value to one decimal place
      //return Math.ceil(lastMeterValue.activeEnergy * lastMeterValue.factor * 10) / 10
      //no round & 2 decimals
      return (lastMeterValue.activeEnergy * lastMeterValue.factor).toFixed(2)
    }
  },

  actions: {
    bindEvents() {
      socket.on(TopicNameSpace.meterValues, (data: MeterValuesBroadcast) => {
        const { cpId, payload } = data
        const connectorId = payload.connectorId
        this.setMeterValues(cpId, connectorId, payload)
      })
    },

    setMeterValues(cpId: string, connectorId: number, meterValues: MeterValuesRequest) {
      this.meterValues = {
        ...this.meterValues,
        [`${cpId}:${connectorId}`]: { ...meterValues, cpId }
      }
    }
  }
})
