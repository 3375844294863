import type { MeterValue } from '@/models/ocpp/MeterValuesRequest'
import type { Measurand } from '@/models/ocpp/enums'

export const getMostRecentMeterValue = (meterValue: MeterValue[]) => {
  return meterValue.reduce((mostRecent, current) => {
    return new Date(current.timestamp) > new Date(mostRecent.timestamp) ? current : mostRecent
  })
}

const sortMeterValuesNewest = (meterValues: MeterValue[]) => {
  return meterValues.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  )
}

export const findMeterValueByMeasurand = (meterValues: MeterValue[], measurand: Measurand) => {
  const sortMeterValues = sortMeterValuesNewest(meterValues)
  let found = false
  let meterValue: MeterValue | null = null
  for (let i = 0; i < sortMeterValues.length && !found; i++) {
    for (let j = 0; j < sortMeterValues[i].sampledValue.length && !found; j++) {
      const sampledValue = sortMeterValues[i].sampledValue[j]
      if (sampledValue.measurand) {
        if (sampledValue.measurand === measurand) {
          found = true
          meterValue = sortMeterValues[i]
        }
      }
    }
  }
  return meterValue
}
