import { defineStore } from 'pinia'
import { socket } from '@/config/socket'
import type {
  ActionRequested,
  ChangeAvailabilityBroadcast,
  ReserveNowBroadcast,
  UnlockConnectorBroadcast
} from '@/models/ocpp/Broadcast'
import { TopicNameSpace } from '@/models/common/TopicNameSpace'

//TODO related with reserveNow is WIP
export const useResponsesOcpp = defineStore('responsesOcpp', {
  state: () => ({
    changeAvailability: {} as { [key: string]: ChangeAvailabilityBroadcast },
    unlockConnector: {} as { [key: string]: UnlockConnectorBroadcast },
    reserveNow: {} as { [key: string]: ReserveNowBroadcast },
    actionRequested: {} as { [key: string]: ActionRequested }
  }),
  getters: {
    getChangeAvailability: (state) => state.changeAvailability,
    getChangeAvailabilityByKey: (state) => (key: string) => {
      return state.changeAvailability[key]
    },
    getUnlockConnector: (state) => state.unlockConnector,
    getUnlockConnectorByKey: (state) => (key: string) => {
      return state.unlockConnector[key]
    },
    getReserveNow: (state) => state.reserveNow,
    getReserveNowByKey: (state) => (key: string) => {
      return state.reserveNow[key]
    },
    getActionRequestedByKey: (state) => (key: string) => {
      return state.actionRequested[key]
    }
  },
  actions: {
    bindEvents() {
      socket.on(TopicNameSpace.actionRequested, (data: ActionRequested) => {
        const { cpId, connectorId, command } = data
        this.setActionRequested(cpId, connectorId, command, data)
      })

      socket.on(TopicNameSpace.unlockConnector, (data: UnlockConnectorBroadcast) => {
        const { cpId } = data
        const { connectorId } = data.request
        this.setUnlockConnector(cpId, connectorId, data)
      })

      socket.on(TopicNameSpace.reserveNow, (data: ReserveNowBroadcast) => {
        const { cpId } = data
        const { connectorId } = data.request
        this.setReserveNow(cpId, connectorId, data)
      })
    },
    setActionRequested(
      cpId: string,
      connectorId: number,
      command: TopicNameSpace,
      data: ActionRequested
    ) {
      this.actionRequested[`${cpId}:${connectorId}:${command}`] = data
    },
    setChangeAvailability(cpId: string, connectorId: number, data: ChangeAvailabilityBroadcast) {
      this.changeAvailability[`${cpId}:${connectorId}`] = data
    },
    setUnlockConnector(cpId: string, connectorId: number, data: UnlockConnectorBroadcast) {
      this.unlockConnector[`${cpId}:${connectorId}`] = data
    },
    setReserveNow(cpId: string, connectorId: number, data: ReserveNowBroadcast) {
      this.reserveNow[`${cpId}:${connectorId}`] = data
    }
  }
})
