export enum TopicNameSpace {
  meterValues = 'MeterValues',
  statusNotification = 'StatusNotification',
  changeAvailability = 'ChangeAvailability',
  unlockConnector = 'UnlockConnector',
  reserveNow = 'ReserveNow',
  cancelReservation = 'CancelReservation',
  chargerStatus = 'ChargerStatus',
  actionRequested = 'ActionRequested'
}
