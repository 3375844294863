import { t } from '@/common/i18n'
import { type ChargePointStatus, StationStatus } from '@/models/ocpp/enums'
import type { StationStatusMap } from '@/models/domain/location/enums'
import { Roles } from '@/models'

export enum CauseEndingStatus {
  OTHER = 'Other',
  UNKNOWN = 'unknown',
  LOCAL = 'Local',
  REMOTE = 'Remote',
  EV_DISCONNECTED = 'EVDisconnected',
  POWER_LOSS = 'PowerLoss',
  EMERGENCY_STOP = 'EmergencyStop'
}
export enum StoreStatus {
  AVAILABLE = 'Available',
  UNAVAILABLE = 'Unavailable',
  FAULTED = 'Faulted',
  PREPARING = 'Preparing',
  RESERVED = 'Reserved',
  SUSPENDED_EVSE = 'SuspendedEVSE',
  SUSPENDED_EV = 'SuspendedEV',
  CHARGING = 'Charging',
  FINISHING = 'Finishing',
  DISCONNECTED = 'Disconnected'
}
export enum StatusHours {
  ENABLED = 0,
  DISABLED = 1,
  UNKNOWN = 2
}

const getCauseEndingTranslations = () => {
  const otherTranslation = t('detail.transactions.header.other')
  return {
    [CauseEndingStatus.OTHER]: otherTranslation,
    [CauseEndingStatus.UNKNOWN]: otherTranslation,
    [CauseEndingStatus.LOCAL]: t('detail.transactions.header.local'),
    [CauseEndingStatus.REMOTE]: t('detail.transactions.header.remote'),
    [CauseEndingStatus.EV_DISCONNECTED]: t('detail.transactions.header.evdisconnected'),
    [CauseEndingStatus.POWER_LOSS]: t('detail.transactions.header.powerloss'),
    [CauseEndingStatus.EMERGENCY_STOP]: t('detail.transactions.header.emergencyStop')
  }
}

const getTypeRoles = () => ({
  [Roles.admin]: t('roles.admin'),
  [Roles.manager]: t('roles.manager'),
  [Roles.owner]: t('roles.owner'),
  [Roles.technician]: t('roles.technician'),
  [Roles.viewer]: t('roles.viewer')
})

export const evaluateStatus = (status: string): string => getCauseEndingTranslations()[status] || ''

export const evaluateRole = (role: string): string => getTypeRoles()[role] || ''

export const getClassForStatusStore = (
  status: ChargePointStatus | StationStatus | StationStatusMap
): string => {
  const statusClasses = {
    [StoreStatus.AVAILABLE]: 'available',
    [StoreStatus.UNAVAILABLE]: 'unavailable',
    [StoreStatus.FAULTED]: 'faulted',
    [StoreStatus.PREPARING]: 'preparing',
    [StoreStatus.RESERVED]: 'reserved',
    [StoreStatus.SUSPENDED_EVSE]: 'suspended',
    [StoreStatus.SUSPENDED_EV]: 'suspended',
    [StoreStatus.CHARGING]: 'charging',
    [StoreStatus.FINISHING]: 'finishing',
    [StoreStatus.DISCONNECTED]: 'disconnected'
  }

  return statusClasses[status] || statusClasses[StoreStatus.DISCONNECTED]
}

export const getStatusTranslationStore = (
  status: ChargePointStatus | StationStatus | StationStatusMap
): string => {
  const statusTranslations = {
    [StoreStatus.AVAILABLE]: t('status.available'),
    [StoreStatus.UNAVAILABLE]: t('status.unavailable'),
    [StoreStatus.FAULTED]: t('status.faulted'),
    [StoreStatus.PREPARING]: t('status.preparing'),
    [StoreStatus.SUSPENDED_EVSE]: t('status.suspendedEVSE'),
    [StoreStatus.SUSPENDED_EV]: t('status.suspendedEV'),
    [StoreStatus.CHARGING]: t('status.charging'),
    [StoreStatus.FINISHING]: t('status.finishing'),
    [StoreStatus.RESERVED]: t('status.reserved'),
    [StoreStatus.DISCONNECTED]: t('status.disconnected')
  }

  return statusTranslations[status] || statusTranslations.Disconnected
}

export const getStatusEnabledHours = (status: number): string => {
  const scheduleTranslations = {
    [StatusHours.ENABLED]: 'enabled',
    [StatusHours.DISABLED]: 'disabled'
  }
  return scheduleTranslations[status] || CauseEndingStatus.UNKNOWN
}
export const getStatusCard = (status: number): string => {
  const statusRFIDCard = {
    true: 'enabled',
    false: 'disabled'
  }
  return statusRFIDCard[status]
}
export const getCauseEnding = () => [
  { name: t('detail.transactions.header.other'), status: 'Other', icon: 'emergency' },
  { name: t('detail.transactions.header.local'), status: 'Local', icon: 'stop-locally' },
  { name: t('detail.transactions.header.remote'), status: 'Remote', icon: 'stop-remote' },
  {
    name: t('detail.transactions.header.evdisconnected'),
    status: 'EVDisconnected',
    icon: 'car-disconnected'
  },
  {
    name: t('detail.transactions.header.powerloss'),
    status: 'PowerLoss',
    icon: 'charger-disconnected'
  },
  {
    name: t('detail.transactions.header.emergencyStop'),
    status: 'EmergencyStop',
    icon: 'emergency'
  }
]
export const getIconForCauseEnding = (status: string) => {
  const searchStatus = status === CauseEndingStatus.UNKNOWN ? CauseEndingStatus.OTHER : status
  const item = getCauseEnding().find((c) => c.status === searchStatus)
  return item ? item.icon : ''
}
