import { computed, reactive, ref, toRefs } from 'vue'

const layoutConfig = reactive({
  ripple: false,
  darkTheme: false,
  inputStyle: 'outlined',
  menuMode: 'static',
  theme: 'lara-light-indigo',
  scale: 14,
  activeMenuItem: null
})

const layoutState = reactive({
  staticMenuDesktopInactive: false,
  overlayMenuActive: false,
  profileSidebarVisible: false,
  configSidebarVisible: false,
  staticMenuMobileActive: false,
  menuHoverActive: false
})

export function useLayout() {
  const setScale = (scale) => {
    layoutConfig.scale = scale
  }

  const setActiveMenuItem = (item) => {
    layoutConfig.activeMenuItem = item.value || item
  }
  const isCollapsed = ref(false)
  const onMenuToggle = () => {
    if (layoutConfig.menuMode === 'overlay') {
      layoutState.overlayMenuActive = !layoutState.overlayMenuActive
    }

    if (window.innerWidth > 991) {
      layoutState.staticMenuDesktopInactive = !layoutState.staticMenuDesktopInactive
    } else {
      layoutState.staticMenuMobileActive = !layoutState.staticMenuMobileActive
    }
    isCollapsed.value = !isCollapsed.value
  }
  const onWindowResize = (value) => {
    if (layoutConfig.menuMode === 'overlay') {
      layoutState.overlayMenuActive = value
    }

    if (window.innerWidth > 991) {
      layoutState.staticMenuDesktopInactive = value
    } else {
      layoutState.staticMenuMobileActive = !value
    }
    isCollapsed.value = value
  }

  const isSidebarActive = computed(
    () => layoutState.overlayMenuActive || layoutState.staticMenuMobileActive
  )

  return {
    layoutConfig: toRefs(layoutConfig),
    layoutState: toRefs(layoutState),
    onMenuToggle,
    onWindowResize,
    setScale,
    isSidebarActive,
    isCollapsed,
    setActiveMenuItem
  }
}
