import { ref, watch } from 'vue'
import { createI18n } from 'vue-i18n'
import en from '@/i18n/en.json'
import es from '@/i18n/es.json'
import fr from '@/i18n/fr.json'
import de from '@/i18n/de.json'
import pt from '@/i18n/pt.json'
import it from '@/i18n/it.json'

enum Locales {
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  DE = 'de',
  PT = 'pt',
  IT = 'it'
}

export type MessageSchema = typeof es
export type availableLanguages = 'en' | 'es' | 'fr' | 'de' | 'pt' | 'it'
export type MessagesType = {
  [key in Locales]: MessageSchema
}
export const messages = {
  [Locales.EN]: en,
  [Locales.ES]: es,
  [Locales.FR]: fr,
  [Locales.DE]: de,
  [Locales.PT]: pt,
  [Locales.IT]: it
}
export const defaultLocale = navigator.language as availableLanguages
export const i18n = createI18n<[MessageSchema], availableLanguages>({
  locale: defaultLocale,
  globalInjection: true,
  fallbackLocale: defaultLocale,
  messages: messages as any
})
export const t = i18n.global.t
const html = document.documentElement
export const monthNames = ref([
  t('locale.months.january'),
  t('locale.months.february'),
  t('locale.months.march'),
  t('locale.months.april'),
  t('locale.months.may'),
  t('locale.months.june'),
  t('locale.months.july'),
  t('locale.months.august'),
  t('locale.months.september'),
  t('locale.months.october'),
  t('locale.months.november'),
  t('locale.months.december')
])
export const dayNames = ref([
  t('locale.days.monday'),
  t('locale.days.tuesday'),
  t('locale.days.wednesday'),
  t('locale.days.thursday'),
  t('locale.days.friday'),
  t('locale.days.saturday'),
  t('locale.days.sunday')
])
export const dateFormat = ref(i18n.global.locale === Locales.ES ? 'dd/mm/yy' : 'mm/dd/yy')
export const today = ref(t('locale.today'))
export const clear = ref(t('actions.delete'))
export const firstDayOfWeek = ref(i18n.global.locale === Locales.ES ? 1 : 0)
export const pending = ref(t('status.pending'))
watch(
  () => i18n.global.locale,
  () => {
    monthNames.value = [
      t('locale.months.january'),
      t('locale.months.february'),
      t('locale.months.march'),
      t('locale.months.april'),
      t('locale.months.may'),
      t('locale.months.june'),
      t('locale.months.july'),
      t('locale.months.august'),
      t('locale.months.september'),
      t('locale.months.october'),
      t('locale.months.november'),
      t('locale.months.december')
    ]
    dayNames.value = [
      t('locale.days.monday'),
      t('locale.days.tuesday'),
      t('locale.days.wednesday'),
      t('locale.days.thursday'),
      t('locale.days.friday'),
      t('locale.days.saturday'),
      t('locale.days.sunday')
    ]
    dateFormat.value = i18n.global.locale === Locales.ES ? 'dd/mm/yy' : 'mm/dd/yy'
    firstDayOfWeek.value = i18n.global.locale === Locales.ES ? 1 : 0
    today.value = t('locale.today')
    clear.value = t('actions.delete')
    pending.value = t('status.pending')
  }
)
export const status: {
  [key: string]: string
} = {
  pending: t('status.pending')
}
html.setAttribute('lang', defaultLocale)
