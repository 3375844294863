<script setup lang="ts">
import type { Ref } from 'vue'
import { onBeforeMount, ref } from 'vue'

import { dayNames } from '@/common/i18n'
import type { Schedule } from '@/models/ui/Schedule'

const daysOfWeek: string[] = dayNames.value
const hoursOfDay: number[] = Array.from({ length: 24 }, (_, index) => index)
const availability: Ref<{ [key: string]: { [key: number]: boolean } }> = ref({})
const { header = '' } = defineProps<Schedule>()
const updateModelValue = 'update:modelValue'
const emit = defineEmits<{
  (e: typeof updateModelValue, value: boolean): void
}>()
const updateAvailability = (day: string, hour: number) => {
  console.log(`Día: ${day}, Hora: ${hour}, Disponible: ${availability.value[day][hour]}`)
  emit('update:modelValue', availability.value[day][hour])
  console.log(availability.value)
}
onBeforeMount(() => {
  daysOfWeek.forEach((day) => {
    availability.value[day] = {}
    hoursOfDay.forEach((hour) => {
      availability.value[day][hour] = false
    })
  })
})
</script>
<template>
  <div class="flex flex-column col-12">
    <label class="field font-family-light text-xl">{{ header }}</label>
    <table>
      <thead>
        <tr>
          <th></th>
          <th v-for="day in daysOfWeek" :key="day">{{ day }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="hour in hoursOfDay" :key="hour">
          <td>{{ hour }}:00</td>
          <td v-for="day in daysOfWeek" :key="day">
            <input
              type="checkbox"
              v-model="availability[day][hour]"
              @change="updateAvailability(day, hour)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped lang="scss">
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid var(--lightGrayDetail);
  padding: 7px;
  text-align: center;
}

th {
  background-color: var(--lightGrayDetail);
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--darkGray) !important;
  background-color: var(--darkGray) !important;
}
</style>
